@import '@julaya/common/scss/system-variables';

.jla-table-cell {
  display: flex;
  min-height: 32px;
  align-items: center;

  &--left {
    justify-content: flex-start;
    margin-left: auto;
  }

  &--center {
    justify-content: center;
    margin: auto;
  }

  &--right {
    justify-content: flex-end;
    margin-right: auto;
  }
  
  &__multiple {
    p{
      margin-bottom: 0;
    }
    display: flex;
    flex-flow: column;
    margin-bottom: 0px;
  }
}
