@import '@julaya/common/scss/system-variables';
@import '@julaya/common/scss/css/core/icons';

.jla-accordion {
  $root: &;
  width: 100%;
  border-radius: 0.625rem;
  cursor: pointer;

  &--gray {
    background-color: $neutral-50;
  }

  &--white {
    background-color: $white;
  }

  &__label {
    flex-grow: 1;
    font-weight: $lead-font-weight;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    color: $neutral-900;

    line-height: 32px;
    min-height: 32px;
    margin-right: 1rem;

    &--left {
      margin-left: 1rem;
    }
  }

  &__action-btn {
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $neutral-200;
    color: $neutral-900;

    &--open i {
      transform: rotate(180deg);
    }

    &--disabled i {
      transform: rotate(0deg);
    }

    i {
      transition: 0.3s all;
    }
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &--disabled {
      cursor: not-allowed;
      #{$root} {
        &__action-btn {
          background-color: $neutral-200;
          color: $neutral-400;
        }
        &__label {
          color: $neutral-400;
        }
      }
    }

    &:not(&--disabled):hover {
      cursor: pointer;

      #{$root} {
        &__action-btn {
          background-color: $neutral-300;
          color: $neutral-900;
        }
      }
    }
  }

  &__body {
    transition: 0.3s all;
    overflow: auto;
  }

  &__body-children {
    &--disabled {
      opacity: 0.5;
    }

    border-radius: 0.625rem;

    &--default-bg {
      background-color: $primary-50;
    }

    &--gray {
      background-color: $neutral-50;
    }

    &--no-padding {
      padding: 0 !important;
    }
  }

  &__no-children {
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
  }

  &--sm {
    #{$root}__suffix-block {
      margin-right: 12px;

      i {
        font-size: 20px;
        color: $neutral-900;
        opacity: 0.2;
        border-radius: 4px;
      }
    }

    #{$root}__action-btn {
      padding: 0.375rem;
      width: 2rem;
      height: 2rem;

      i {
        font-size: 1rem;
      }
    }

    #{$root}__header {
      padding: 0.5rem 1rem;
    }

    #{$root}__body-inner {
      padding: 0.5rem 1rem;
    }
    #{$root}__body-children {
      padding: 1rem;
      margin-top: 1rem;
    }
  }

  &--md {
    #{$root}__suffix-block {
      margin-right: 1rem;
    }

    #{$root}__action-btn {
      padding: 0.375rem;
      width: 2.5rem;
      height: 2.5rem;

      i {
        font-size: 1.25rem;
      }
    }

    #{$root}__header {
      padding: 1rem;
      border-bottom: 2px solid $neutral-100;
    }

    #{$root}__body-inner {
      padding: 1rem;
    }
    #{$root}__body-children {
      padding: 1rem;
    }
  }
}
