.historyList {
  .title {
    // @extend .mb-1;
    font-size: 1rem;
    font-weight: bold;
  }
  .history {
    .history-item {
      @extend .rounded, .px-3, .py-2;
      display: flex;
      align-items: center;
      margin-bottom: 0.6rem;

      font-size: 12px;
      background: $white;

      .title {
        @extend .mt-1;
      }

      .list-icon {
        &:before {
          display: none;
        }
        background-color: rgba($carbon, 0.2);
        i {
          color: $carbon;
        }
        &.icon-swap-horiz {
          background-color: rgba($carbon, 0.2);
          i {
            color: $carbon;
          }
        }
        &.reload,
        &.icon-wallet {
          background-color: rgba($purple, 0.2);
          i {
            color: $purple;
          }
        }
        &.icon-deposit {
          background-color: rgba($dark-green, 0.2);
          i {
            color: $dark-green;
          }
        }
      }

      .icon-container {
        // padding: 0.6rem;
        width: 2.25rem !important;
        min-width: 2.25rem !important;
        height: 2.25rem !important;
        margin-right: 0.6rem;
        border-radius: 5px;
        color: $black;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;

        &.primary {
          color: $primary;
          background-color: rgba($primary, 0.2);
        }
        &.warning {
          color: $warning;
          background-color: rgba($warning, 0.2);
        }
        &.success {
          color: $success;
          background-color: rgba($success, 0.2);
        }
        &.danger {
          color: $danger;
          background-color: rgba($danger, 0.2);
        }
        &.white {
          color: $white;
          background-color: rgba($white, 0.2);
        }
        &.grey {
          color: $secondary;
          background-color: rgba(#000, 0.1);
        }
      }
      .title {
        font-size: 0.75rem;
      }
      .date {
        margin-top: 0.15rem;
        @extend .mb-1;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.history-details {
  .icon-container {
    width: 2.5rem !important;
    min-width: 2.5rem !important;
    height: 2.5rem !important;
    margin-right: 0.6rem;
    border-radius: 5px;
    color: $black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    background-color: rgba($text-muted, 0.2);
    &.primary {
      color: $primary;
      background-color: rgba($primary, 0.2);
    }
    &.warning {
      color: $warning;
      background-color: rgba($warning, 0.2);
    }
    &.success {
      color: $success;
      background-color: rgba($success, 0.2);
    }
    &.danger {
      color: $danger;
      background-color: rgba($danger, 0.2);
    }
    &.white {
      color: $white;
      background-color: rgba($white, 0.2);
    }
    &.grey {
      color: $black;
      background-color: rgba(#000, 0.1);
    }
  }
  .status-pill {
    @extend .rounded, .align-items-center, .justify-content-center;
    display: inline-flex;
    padding: 0.3rem 0.7rem;

    i {
      font-size: 1rem;
      margin-right: 0.4rem;
    }

    &.danger {
      i {
        color: darken($danger, 1);
      }
      background-color: rgba($danger, 0.2);
    }
    &.todo {
      i {
        color: $white;
      }
      background-color: $danger;
    }
    &.success {
      i {
        color: darken($success, 1);
      }
      background-color: rgba($success, 0.2);
    }
    &.warning {
      i {
        color: darken($warning, 1);
      }
      background-color: rgba($warning, 0.2);
    }
    &.grey {
      i {
        color: $black;
      }
      background-color: rgba(#000, 0.1);
    }
    &.primary {
      i {
        color: $white;
      }
      background-color: var(--primary);
    }
    &.white {
      background-color: $white;
    }
  }
}
