// stylelint-disable declaration-no-important

@each $value in $overflows {
  .overflow-#{$value} {
    overflow: $value !important;
  }
  .overflow-y-#{$value} {
    overflow-y: $value !important;
    overflow-x: hidden !important;
  }
  .overflow-x-#{$value} {
    overflow-x: $value !important;
    overflow-y: hidden !important;
  }
}
