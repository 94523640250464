@import '@julaya/common/scss/system-variables';

%defaultText {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: $neutral-900;
}

.jla-card {
  border-radius: 10px;

  &--large {
    padding: 16px;
  }
  &--medium {
    padding: 8px 16px;
  }

  &--white {
    background-color: $white;
  }
  &--outline {
    border: 1px solid $neutral-200;
  }
  &--grey {
    background-color: $neutral-50;
  }

  &__default-content,
  &__service-content,
  &__default-footer,
  &__account,
  &__select {
    display: flex;
    justify-self: flex-start;
    align-items: center;
  }

  &__default,
  &__service {
    display: flex;
    flex-direction: column;
    justify-self: flex-start;
  }

  &__default-footer {
    margin-top: $spacing;
  }

  &__card-image {
    width: 40px;
    height: 40px;

    &--prefix {
      margin-right: 8px;
      background-color: $primary-50;
      color: $primary-500;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      border-radius: 10px;
    }
  }

  &__card-icon {
    width: 40px;
    height: 40px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

    &--prefix {
      margin-right: 8px;
      background-color: $primary-50;
      color: $primary-500;
    }
    &--suffix {
      margin-left: 16px;
      background-color: $neutral-200;
      color: $neutral-900;
    }
    &--select {
      width: 32px;
      height: 32px;
    }
  }
  &__card-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;
  }
  &__card-title {
    @extend %defaultText;
    font-weight: 700;
  }
  &__card-dot {
    border-radius: 9999px;
    background-color: $primary-500;
    margin: 0 8px;
    width: 4px;
    height: 4px;
  }
  &__card-subtitle,
  &__card-suffix-label {
    @extend %defaultText;
  }
  &__card-suffix-button {
    margin-left: 8px;
  }

  &__card-label-list {
    display: flex;
    flex-flow: column;
    gap: 8px;
  }
  &__card-label-icon {
    font-size: 20px;
    color: $neutral-900;
    margin-right: 8px;
  }
  &__card-label-title {
    @extend %defaultText;
  }

  &__account-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__account-avatar {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border-radius: 6px;
    background-size: contain;
  }
  &__account-name {
    @extend %defaultText;
    font-size: 12px;
  }
  &__account-label-list {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-around;
  }
  &__account-label-item {
    @extend %defaultText;
    font-weight: 700;
    font-size: 12px;
  }
  &__account-card-icon {
    font-size: 20px;
    margin-left: 8px;
    color: $neutral-900;
  }
  &__account-balance {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: $neutral-900;
  }

  &__select-card-title {
    @extend %defaultText;
    font-weight: 700;
    color: $neutral-500;
  }
}
