#page-login {
  width: 100%;
}

/*
|
| MOBILE
| --------
*/
.auth-layout {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: $white;
  overflow: hidden;
}

.form-group {
  .form-control {
    border-radius: 0.675rem;
    background-color: $bg-grey;
    border: 0;

    &::placeholder {
      color: $gray-500;
    }

    input {
      &::placeholder {
        color: $gray-500;
      }
    }
  }
}
.btn {
  border-radius: 0.675rem;
}

/*
|
| DUPLICATE
| -----------
*/
.custom-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 21.875rem;
  height: 100%;

  .login__title {
    font-size: 2rem;
    font-weight: 700;
  }
}

.login {
  @extend .custom-box;

  &__logo {
    img {
      width: 6rem;
      &.logo-custom {
        width: 15rem;
      }
    }
  }

  &__subtitle,
  &__register {
    font-size: 0.875rem;

    button {
      background: transparent;
      border: 0;
      text-decoration: underline;
      color: $black;

      &:focus {
        outline: none;
      }
    }
  }

  .form-group__password {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .form-control {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      height: auto;
      overflow: hidden;
      padding-right: 0.9375rem;
    }

    input {
      height: calc(1.4rem + 1.35rem + 2px);
      padding: 0.675rem 0.9375rem;
      border: 0;
      flex: 1;
      background: $bg-grey;

      &:focus-visible {
        outline: none;
      }
    }
  }
}

.random-image {
  height: 100%;
  background-position: center;
  background-size: cover;
}
