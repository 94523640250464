@import "@julaya/common/scss/system-variables";
@import "@julaya/common/scss/system";

$jla-expand-border-radius: 0.625rem;
$jla-expand-font-size: $font-size-base; // 14px
$jla-expand-font-weight: $lead-font-weight;

.jla-expand {
  $self: &;
  border-radius: $jla-expand-border-radius;
  background-color: $neutral-100;
  overflow: hidden;

  &--open {
    #{$self}__body {
      max-height: 10rem;
    }

    #{$self}__fit-content {
      max-height: fit-content;
    }
  }

  &__header {
    padding: 1rem;
  }

  &__body {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }

  &__inner {
    padding: 1rem;
  }

  &--between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--light {
    background-color: $neutral-50;
  }
}
