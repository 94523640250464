// html,
// body {
//   height: 100vh;
// }

html {
  overflow: hidden;
}
body {
  display: flex;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

#app {
  background-color: $bg-grey;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.dropdown-datetime {
  position: relative;
  @extend .d-flex, .align-items-center, .justify-content-between;

  .dropdown-btn,
  .dropdown-datetime-btn {
    cursor: pointer;
    padding-top: 0.675rem;
    padding-bottom: 0.675rem;
    @extend .d-flex, .align-items-center, .justify-content-between, .px-3, .rounded;

    .icon-chevron-down {
      transition: 0.5s all;
    }
  }

  .dropdown-content,
  .dropdown-datetime-content {
    position: absolute;
    z-index: 1;

    width: 19rem;
    left: 0;
    top: calc(100% + 0.675rem);

    @extend .rounded;
    background: $white;
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.15);

    transition: 0.3s all;
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
  }
}

.bg-gradient {
  background-image: linear-gradient(-45deg, $secondary 0%, $primary 100%);
}
.bg-error {
  color: $red;
  background: rgba($red, 0.2);
}

.text-sm {
  font-size: $font-size-base / 1.35;
}
.text-lg {
  font-size: $font-size-lg;
}

.avatar-lg {
  width: 6rem;
  height: 6rem;
}
// /*File upload*/
.hidden-file-input {
  display: none;
  visibility: hidden;
  width: 0px;
  height: 0px;
}
.file-upload {
  border: 2px dashed #5856c6;
  border-radius: 3px;
  display: flex;
  padding: 0 1.5rem;
  align-items: center;
  line-height: 1;
}

.file-upload-primary {
  padding: 1.5rem;
  border-radius: 3px;
  border: 1px dashed $middle;
}

.icon-container {
  @extend .rounded, .d-flex, .align-items-center, .justify-content-center;
  height: 2.9rem;
  width: 2.9rem;
  i {
    @extend .h4, .m-0, .font-weight-normal;
  }
}

.strikethrough {
  text-decoration: line-through;
}

.bullet-icon {
  width: 20px;
  height: 20px;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    font-size: 0.625rem;
  }
}
