@import '@julaya/common/scss/system-variables';

.jla-table-body{
  tr{
    &.clickable{
      cursor: pointer;
      &:hover{
        td{
          background-color: $neutral-50;
        }
      }
    }
    td{
      padding-bottom: 10px;
      background-color: $white;
      &:first-child {
        border-radius: 10px 0 0 10px;
      }
      &:last-child {
        border-radius: 0 10px 10px 0;
      }
    }
  }
}