// ***
// * SCSS

@import '@julaya/common/scss/system-variables';

$jla-radio-base-size: 1.25rem;
$jla-radio-color: $white;
$jla-radio-border-radius: 50%;

// no state
$jla-radio-checked-color: $primary-500;
$jla-radio-checked-border-color: $primary-500;
$jla-radio-unchecked-color: $white;
$jla-radio-unchecked-border-color: $neutral-300;

//hover

$jla-radio-checked-hover-color: $primary-600;
$jla-radio-checked-hover-border-color: $primary-600;
$jla-radio-unchecked-hover-color: $neutral-100;
$jla-radio-unchecked-hover-border-color: $neutral-300;

// disabled
$jla-radio-checked-disabled-color: $primary-300;
$jla-radio-checked-disabled-border-color: $primary-300;
$jla-radio-unchecked-disabled-color: $white;
$jla-radio-unchecked-disabled-border-color: $neutral-100;

.jla-radio {
  $self: &;
  display: flex;
  align-items: flex-start;
  position: relative;

  &__heading-wrap {
    margin-left: 0.75rem;
    font-size: 0.875rem;
  }

  &__heading {
    font-weight: 700;
    line-height: 0.75rem;
    color: $neutral-900;
    cursor: pointer;
  }
  &__heading-descr {
    font-weight: 500;
    line-height: 0.75rem;
    color: $neutral-500;
  }

  #{$self}__heading-descr-long {
    line-height: 1rem;
  }

  &__ui {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $jla-radio-base-size;
    height: $jla-radio-base-size;
    border-radius: $jla-radio-border-radius;
    background-color: $jla-radio-unchecked-color;
    border-color: $jla-radio-unchecked-border-color;
    border-style: solid;
    border-width: 1px;
    transition: all 0.3s;
    margin-bottom: 0;
    flex-shrink: 0;
    cursor: pointer;
    &:hover {
      background-color: $jla-radio-unchecked-hover-color;
      border-color: $jla-radio-unchecked-hover-border-color;
    }
    &:before {
      position: relative;
      display: inline-block;
      content: '\e9ab';
      font-family: 'julaya-v2' !important;
      font-size: 1rem;
      color: transparent;
    }
  }

  &__input:checked + &__ui {
    background-color: $jla-radio-checked-color;
    border-color: $jla-radio-checked-border-color;
    &:hover {
      background-color: $jla-radio-checked-hover-color;
      border-color: $jla-radio-checked-hover-border-color;
    }
    &:before {
      color: $jla-radio-color;
      content: '\e9ab';
    }
  }

  &__input:disabled + &__ui {
    background-color: $jla-radio-unchecked-disabled-color;
    border-color: $jla-radio-unchecked-disabled-border-color;
  }

  &__input:checked:disabled + &__ui {
    background-color: $jla-radio-checked-disabled-color;
    border-color: $jla-radio-checked-disabled-border-color;
  }

  &__input:disabled + &__ui + &__heading-wrap {
    opacity: 0.5;
  }

  &__input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.095rem;
    opacity: 0;
  }

  //label
  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.875rem;

    label {
      font-weight: 700;
      line-height: 0.75rem;
      color: $neutral-900;
    }

    &--disabled {
      label {
        color: $neutral-400;
      }
    }

    #{$self}__label-hint {
      font-weight: 500;
      line-height: 0.75rem;
      color: $neutral-500;
    }
  }
}
