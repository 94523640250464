@mixin anim-mixin {
  overflow: hidden;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: backgroundAnimation;
  animation-timing-function: linear;
  background: linear-gradient(to right, #e9eaf0 8%, #d5d8e3 18%, #e9eaf0 33%);
  background-size: 800px 104px;
}

@keyframes backgroundAnimation {
  0% {
    background-position: -500px;
  }
  100% {
    background-position: 500px;
  }
}

.placeholder {
  @include anim-mixin;
}

.placeholder-transaction-type {
  @include anim-mixin;
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  border-radius: 50%;
}
.placeholder-status {
  @include anim-mixin;
  height: 31px;
  min-height: 31px;
  border-radius: $border-radius;
}
.placeholder-line {
  @include anim-mixin;
  height: 8px;
  display: block;
  border-radius: 50px;
}
.placeholder-square {
  @include anim-mixin;
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  border-radius: 10%;
}
