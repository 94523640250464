// ***
// * SCSS

@import '@julaya/common/scss/system-variables';

$jla-toggle-base-size: 1.25rem;
$jla-toggle-color: $white;
$jla-toggle-checked-color: $primary-500;
$jla-toggle-unchecked-color: $neutral-200;

$jla-toggle-checked-hover-color: $primary-600;
$jla-toggle-unchecked-hover-color: $neutral-300;

$jla-toggle-checked-disabled-color: $primary-300;
$jla-toggle-unchecked-disabled-color: $neutral-100;

.jla-toggle {
  $self: &;
  display: flex;
  align-items: flex-start;

  &__ui {
    display: flex;
    align-items: center;
    margin-bottom: 0 !important;

    span {
      width: $jla-toggle-base-size * 2;
      height: $jla-toggle-base-size;
      position: relative;
      display: block;
    }

    span:first-child {
      position: relative;
      display: block;
      padding: 2px;
      width: $jla-toggle-base-size * 2;
      height: $jla-toggle-base-size;
      border-radius: 0.9375rem;
      background: $jla-toggle-unchecked-color;
      transition: all 0.3s;
      cursor: pointer;
      &:hover {
        background-color: $jla-toggle-unchecked-hover-color;
      }
    }

    span:first-child::before,
    span:first-child::after {
      content: '';
      position: absolute;
    }

    span:first-child::before {
      left: 2px;
      top: 2px;
      width: 1rem;
      height: 1rem;
      background: $jla-toggle-color;
      border-radius: 50%;
      z-index: 1;
      transition: transform 0.3s;
    }

    span:first-child::after {
      right: 3px;
      line-height: 1rem;
      content: '\ea0b';
      font-family: 'julaya-v2' !important;
      font-size: 1rem;
      color: $jla-toggle-color;
    }
  }

  &__heading-wrap {
    margin-left: 0.75rem;
    font-size: 0.875rem;
  }

  &__heading {
    font-weight: 700;
    line-height: 0.75rem;
    color: $neutral-900;
    margin-bottom: 0 !important;
  }
  &__heading-descr {
    margin-top: 0.5rem !important;
    font-weight: 500;
    line-height: 0.75rem;
    color: $neutral-500;
  }

  &__input:checked + &__ui span:first-child {
    background: $jla-toggle-checked-color;
    &:hover {
      background-color: $jla-toggle-checked-hover-color;
    }
  }

  &__input:disabled + &__ui span:first-child {
    background: $jla-toggle-unchecked-disabled-color;
  }
  &__input:checked:disabled + &__ui span:first-child {
    background: $jla-toggle-checked-disabled-color;
  }

  &__input:checked + &__ui span:first-child::before {
    transform: translateX(20px);
  }

  &__input:checked + &__ui span:first-child::after {
    left: 3px;
    line-height: 1rem;
    content: '\e9ab';
    font-family: 'julaya-v2' !important;
    font-size: 1rem;
    color: $jla-toggle-color;
  }

  &__input:disabled + &__ui + &__heading-wrap {
    opacity: 0.5;
  }

  &__input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.095rem;
    opacity: 0;
  }

  //label
  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.875rem;

    label {
      font-weight: 700;
      line-height: 0.75rem;
      color: $neutral-900;
    }

    &--disabled {
      label {
        color: $neutral-400;
      }
    }

    #{$self}__label-hint {
      font-weight: 500;
      line-height: 0.75rem;
      color: $neutral-500;
    }
  }
}
