.wallet-card {
  @extend .d-flex, .justify-content-between, .p-3, .rounded, .mb-3;
  border: 1px solid transparent;
  &--light {
    background-color: var(--white);
  }
  &--dark {
    background-color: $bg-grey;
  }
  &--disabled {
    background-color: var(--white);
    filter: grayscale(1);
  }

  .wallet__info {
    @extend .d-flex, .flex-column, .justify-content-between, .h5, .m-0, .w-100;
  }
  .wallet__info--container {
    display: grid !important;
    grid-template-columns: 55% 43%;
    align-items: center;
    column-gap: 3%;
    .wallet__balance {
      text-align: end;
    }
  }

  .wallet__reference {
    @extend .h5, .mb-3, .text-muted;
  }

  .wallet__reference_dot {
    @extend .h5, .mb-3, .text-muted;
    &:before {
      content: '•';
      font-size: 10px;
      color: var(--primary);
      margin-left: 0.437rem;
      margin-right: 0.437rem;
    }
  }

  .wallet__meta {
    @extend .d-flex, .align-items-center;
    .wallet__type {
      @extend .d-flex, .align-items-center;
      i {
        @extend .d-flex, .justify-content-center, .align-items-center, .mr-2;
        border-radius: 0.4rem !important;
        color: #5856c6;
        background: var(--gradiant-pink-horizontal-2, linear-gradient(90deg, #d1d0ff 0%, #ffdcfc 100%));
        width: 2rem;
        height: 2rem;
        &::before {
          font-size: 1.5rem;
        }
      }
      .wallet__type-label {
        @extend .text-muted, .font-weight-bold, .small;
      }
    }
    .wallet__country {
      @extend .ml-3;
      img {
        @extend .mr-2;
        width: 1.5rem;
      }
      .wallet__country-label {
        @extend .text-muted, .font-weight-bold, .small;
      }
    }
  }

  .wallet__arrow {
    @extend .d-flex, .align-items-center, .font-weight-bold, .h3, .m-0;
  }
}

.wallet-radio-buttons {
  .wallet-wrapper {
    .wallet-card {
      transition: all 300ms ease-in-out;
    }
    &.selected {
      .wallet-card {
        border: 1px solid var(--primary);
        background-color: rgba($carbon, 0.2);
      }
    }
  }
}

.card-info-container {
  position: relative;
  width: 100%;
  max-width: 25rem;
  max-height: 14.6rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 59.09%;
  .card-info {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @extend .rounded;
    background: #d4af37;
    color: $white;
    max-width: 25rem;
    max-height: 14.6rem;
    background-position: center;
    background-size: cover;

    .card-info-inner {
      @extend .p-3;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;

      &.blocked {
        filter: blur(5px);
        user-select: none;
      }

      .card-status {
        position: relative;
        &.active {
          padding-left: 1rem;
          &:before {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            margin-left: 0.2rem;
            margin-right: 0.2rem;
            width: 0.4rem;
            height: 0.4rem;
            border-radius: 0.2rem;
            background-color: $success;
            content: '';
          }
        }
      }
    }

    .blocked-card {
      position: absolute;
      z-index: 1;

      top: 0;
      left: 0;

      height: 100%;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      text-align: center;

      font-size: 0.7rem;

      i {
        font-size: 2.3rem;
      }
    }

    .header {
      display: flex;
      justify-content: space-between;
    }

    .content {
      font-size: 1.1rem;
    }

    .card-footer {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      min-height: 0;

      background: transparent;

      .lastname {
        text-transform: uppercase;
      }
    }
    .logo {
      width: 48px;
      height: 24px;

      @extend %bg-contain;
    }
  }
}
