@import '@julaya/common/scss/system-variables';
@import '@julaya/common/scss/system';

.jla-carousel {
  $self: &;
  display: flex;
  overflow: hidden;
  padding: 1rem;

  #{$self}__control {
    padding: 1rem;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
  }

  #{$self}__body {
    display: flex;
    flex-grow: 1;
    position: relative;
    overflow: hidden;
  }
  #{$self}__content {
    display: flex;
    flex-direction: column;

    gap: 1rem;
    position: relative;
    z-index: 0;
    transition: transform 300ms linear;
    transform: translateX(0px);
  }
}
