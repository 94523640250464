.dropdown-datetime {
  position: relative;
  @extend .d-flex, .align-items-center, .justify-content-between;
  margin-bottom: 0.675rem;

  .dropdown-datetime-btn {
    cursor: pointer;
    padding-top: 0.675rem;
    padding-bottom: 0.675rem;
    @extend .d-flex,
      .align-items-center,
      .justify-content-between,
      .px-3,
      .rounded,
      .bg-white;

    background-color: $bg-grey !important;

    .icon-chevron-down {
      transition: 0.5s all;
    }
  }

  .dropdown-datetime-content {
    position: absolute;
    z-index: 1;

    width: auto;
    left: 0;
    top: calc(100% + 0.675rem);

    @extend .rounded;
    background: $white;
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.15);

    transition: 0.3s all;
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
  }

  /*
  |
  | Open
  | -----------
  */
  &.active {
    .dropdown-datetime-btn {
      .icon-chevron-down {
        transform: rotate(180deg);
      }
    }

    .dropdown-datetime-content {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }

  .rdtPicker {
    position: relative;
    border: 0;
    padding: 0;
    box-shadow: none;

    .rdtActive {
      background-color: var(--primary) !important;
      border-radius: 0.2rem;
      text-shadow: none !important;
    }
  }
}
