@import '@julaya/common/scss/system-variables';

@mixin surface-variant() {
  // bg
  &--bg-grey {
    background-color: $neutral-100;
  }

  &--bg-transparent {
    background-color: transparent;
  }

  // items
  &--items-start {
    align-items: flex-start;
  }
  &--items-end {
    align-items: flex-end;
  }
  &--items-center {
    align-items: center;
  }
  &--items-baseline {
    align-items: baseline;
  }
  &--items-stretch {
    align-items: stretch;
  }

  // justify
  &--justify-start {
    justify-content: flex-start;
  }
  &--justify-end {
    justify-content: flex-end;
  }
  &--justify-center {
    justify-content: center;
  }
  &--justify-between {
    justify-content: space-between;
  }
  &--justify-around {
    justify-content: space-around;
  }
  &--justify-evenly {
    justify-content: space-evenly;
  }

  // direction
  &--row {
    flex-direction: row;
  }
  &--row-reverse {
    flex-direction: row-reverse;
  }
  &--column {
    flex-direction: column;
  }
  &--column-reverse {
    flex-direction: column-reverse;
  }
  // gap
  @each $size, $value in $spacings-map {
    &--gap-#{$size} {
      gap: $value;
    }
  }

  // grow
  &--grow {
    flex-grow: 1;
  }
  &--grow-0 {
    flex-grow: 0;
  }

  // shrink
  &--shrink {
    flex-shrink: 1;
  }
  // shrink
  &--shrink-0 {
    flex-shrink: 0;
  }

  // wrap
  &--wrap {
    flex-wrap: wrap;
  }
  &--wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  &--wrap-nowrap {
    flex-wrap: nowrap;
  }
}

$jla-surface-padding: $spacing;
$jla-surface-border-radius: 0.625rem;
$jla-surface-font-size: $font-size-base; // 14px
$jla-surface-font-weight: $lead-font-weight;

$jla-surface-error-color: $error-500;
$jla-surface-success-color: $success-500;
$jla-surface-warning-color: $warning-500;
$jla-surface-primary-color: $primary-500;
$jla-surface-info-color: $neutral-500;

.jla-surface {
  $self: &;
  display: flex;
  @include surface-variant;
}
.jla-surface__header {
  display: flex;
  border-bottom: 2px solid $neutral-100;
  padding: $jla-surface-padding;
  background-color: $white;

  &--neutral {
    background-color: $neutral-100;
  }

  @include surface-variant;
}

.jla-surface__body {
  display: flex;
  background-color: $white;
  padding: $jla-surface-padding;

  &--neutral {
    background-color: $neutral-100;
  }

  @include surface-variant;
}

.jla-surface__footer {
  display: flex;
  border-top: 2px solid $neutral-100;
  padding: $jla-surface-padding;
  background-color: $white;

  &--neutral {
    background-color: $neutral-100;
  }

  @include surface-variant;
}

.jla-surfaces__section {
  border-radius: $jla-surface-border-radius;
  background-color: $white;
  overflow: hidden;

  &--grey {
    background-color: $neutral-50;
  }

  &--disabled {
    opacity: 0.5;
  }

  &--overflow-visible {
    overflow: visible;
  }
}

.jla-surfaces__section-title {
  font-size: $jla-surface-font-size;
  font-weight: $jla-surface-font-weight;
  color: $neutral-900;
  padding: 1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $neutral-100;
  &--warning {
    color: $jla-surface-warning-color;
  }
  &--primary {
    color: $jla-surface-primary-color;
  }
  &--error {
    color: $jla-surface-error-color;
  }
  &--success {
    color: $jla-surface-success-color;
  }
  &--info {
    color: $jla-surface-info-color;
  }
  &--clickable {
    cursor: pointer;
  }
}

.jla-surfaces__section-details {
  padding: 1rem;
}
