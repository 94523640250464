.tasks {
  overflow: scroll;

  .tasks-container {
    &.success-search-query {
      .isEmpty {
        display: none !important;
      }
    }
  }

  /*
  |
  | Constructor colors
  | Colors => Border | Background
  | --------------------------------------
  | Add Type in /variable.scss -> $colorTypes
  |
  */
  @each $name, $color in $colorStatusTypes {
    .status-#{$name} {
      .pill-status {
        background: rgba($color, 0.3);
      }

      &.status-draft {
        .pill-status {
          background: $white;
        }
      }

      &:not(.waiting) {
        .tasks-card__content {
          &:before {
            background: $color;
          }
        }
      }

      .operation-status {
        background: rgba($color, 0.2);

        // &.to-validate {
        // 	background: rgba($carbon, 0.2);
        // }

        a {
          &:hover {
            color: $white !important;
          }
        }
      }
    }
  }

  .icon-container {
    @extend .rounded;
    font-size: 1.25rem;
    padding: 0.813rem;
    background: $white;
    height: fit-content;
    margin-left: 0.5rem;
  }
}

// Task Detail Page style
.task-detail {
  background-color: $white;
  border-radius: 0.625rem;
  padding: 1rem;
  font-size: 14px;
  &__header {
    display: flex;
    align-items: center;
    .title {
      font-size: 14px;
    }
  }
  &__body {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .task-card__icon {
    @extend .rounded;
    font-size: 1.275rem;
    padding: 12px 14px !important;
    background: rgba(255, 129, 87, 0.1);
    color: $carbon;
  }

  // Dropdown Signataire
  .dropable {
    .dropdown-sign {
      @extend .py-2, .px-3, .d-flex, .align-items-center, .justify-content-between, .rounded;
      width: 100%;
      background: $bg-grey;
      border: 0;
      // i {
      // 	background-color: $white;
      // }
      &:focus {
        outline: none;
      }
    }

    .content {
      position: relative;
      height: auto;
      max-height: 0;
      overflow: hidden;
      transition: max-height 300ms ease-in-out;

      .droppable-item {
        @extend .d-flex, .justify-content-between, .align-items-center, .py-2, .px-3, .rounded, .mt-2;
        // background-color: $white;
        border: 2px solid $bg-grey;

        .number {
          @extend .text-muted, .p-2, .rounded, .bg-grey;
          // background-color: $white;
        }
      }
    }

    i.arrow:before {
      display: block;
      transition: all 300ms ease-in-out;
    }
    &.open {
      .content {
        height: auto;
        max-height: 300px;
      }
      i.arrow:before {
        transform: rotate(90deg);
      }
    }
  }
  // liste transactions
  .right-side__transactions {
    @extend .d-flex, .justify-content-between, .align-items-center, .rounded;
    padding: 0.5rem 1rem;
    width: 100%;

    border: 0;
    background: $bg-grey;
  }

  .disabled {
    opacity: 0.5;
  }

  @each $name, $color in $colorStatusTypes {
    .operation-status {
      background: rgba($color, 0.2);
      &.#{$name} {
        background: rgba($color, 0.2);
        i {
          color: $color;
        }
      }

      &.to-validate {
        background: rgba($carbon, 0.2);
        i {
          color: $white;
        }
      }

      a {
        &:hover {
          color: $white !important;
        }
      }
    }
  }
}

// Task-transfers Card style
.task-transfers {
  &__card {
    display: grid;
    grid-template-columns: 70% 30%;
    row-gap: 0.625rem;
    padding: 1rem;
    background-color: $white;
    border-radius: 0.625rem;
    margin-bottom: 1rem;

    .block-title {
      @extend .font-weight-bold;
      font-size: 0.6875rem;
      margin-bottom: 0.625rem;
    }
    .info_text {
      @extend .font-weight-bold;
      font-size: 0.75rem;
    }

    .identifier {
      &__container {
        @extend .d-flex;
        align-items: center;
        img {
          border-radius: 0.375rem;
          margin-right: 0.625rem;
        }
      }

      &__info {
        .type {
          font-size: 0.75rem;
          margin-bottom: 0;
        }
        .reference {
          font-size: 0.625rem;
          margin-bottom: 0;
        }
      }
    }
  }
}
.task-validate {
  /*
    |
    | Modal
    | --------
    */
  .modal-associates-types-container {
    position: fixed;
    height: 0;
    width: 100vw;
    z-index: 1;

    left: 0;
    bottom: 0;

    background: rgba($black, 0);

    /*
    |
    | Opening Modal
    | --------------
    */
    &.open {
      height: 100vh;
      background: rgba($black, 0.7);

      animation-name: fadeInOpacity;
      animation-iteration-count: 1;
      animation-timing-function: ease-in-out;
      animation-duration: 0.3s;

      .modal-associates-types {
        transform: translateY(0px);
        opacity: 1;
        transition: all 300ms ease-in-out;
      }
    }
  }
  .modal-associates-types {
    background-color: $white;
    border-radius: 8px;

    position: absolute;
    bottom: 0;
    left: 0;

    transform: translateY(100%);
    transform-origin: bottom;
    opacity: 0;
    width: 100%;
    transition: all 300ms ease-in-out;

    .modal-associates-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      padding: 1rem calc(1rem + 15px);
      border-bottom: 1px solid $bg-grey;

      .head-icon {
        height: 40px;
        width: 40px;

        margin-right: 1rem;

        @extend %bg-contain;
      }

      .icon {
        transform: rotate(90deg);
        font-size: 16px;
      }
    }

    .modal-associates-body {
      display: flex;
      flex-wrap: wrap;

      padding: 1rem;

      .transaction-card {
        padding: 1.5rem;

        @extend %card;

        .transaction-card-icon {
          height: 50px;
          width: 50px;
        }
      }
    }
  }

  // Visual Indicators
  .feedback {
    display: flex;
    align-items: center;
    padding: 0.7rem 1rem !important;
    font-weight: bold;
    line-height: 1.5;
    position: relative;

    @each $color, $value in $theme-colors {
      &.feedback-#{$color} {
        background-color: rgba($value, 0.2);
        border-radius: $border-radius-lg * 2;
        i {
          color: $value;
          font-size: $h4-font-size;
          margin-right: 0.5rem;
        }
        .loader {
          background-color: rgba($value, 0.4);
          min-width: 5rem;

          .loader-inner {
            background-color: $value;
          }
        }
      }
    }

    &.min-width {
      min-width: 16rem;
    }

    &.feedback-details {
      font-weight: bold;
      i {
        font-size: $h4-font-size;
        margin-left: 1rem;
      }
      svg {
        position: absolute;

        z-index: -1;

        top: 1px;
        left: 1px;
        width: 100%;
        height: 100%;

        stroke-width: 2px;
        stroke-dasharray: 6;
        overflow: visible;
        stroke: rgba($carbon, 0.4);
        transition: 0.3s all;

        rect {
          width: calc(100% - 3px);
          height: calc(100% - 3px);
          fill: transparent;
        }
      }
      &:hover {
        .info {
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        }
      }
    }

    .info {
      @extend .shadow, .rounded, .bg-white;
      position: absolute;
      bottom: 140%;
      left: 0;
      right: 0;
      position: absolute;
      opacity: 0;
      visibility: hidden;
      transform: translateX(10px);
      transform: translateY(10px);
      transition: 0.3s all;
    }
  }
  .recap-button {
    bottom: 4%;
    left: 12%;
    position: absolute;
  }
}

.footer {
  @extend .py-2, .px-2, .d-flex, .justify-content-end, .align-items-center, .shadow, .bg-white, .rounded;
  left: 0;
  right: 1rem;
  bottom: 0.5rem;
  position: absolute;
  z-index: 1;
}
