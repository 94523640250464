.stats {
  .meter {
    background-color: $light-grey;
    border-radius: 3px;
    overflow: hidden;
    .meter-inner {
      border-radius: 3px;
      height: 6px;
      background-color: $secondary;
    }
  }
}
.social {
  a {
    width: 2.5rem;
    height: 2.5rem;
    background-color: $secondary;
    color: $white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.logout {
  display: block;
  color: $danger;
  background-color: rgba($danger, 0.2);
}
