@import '@julaya/common/scss/system-variables';

.jla-textarea {
  $root: &;
  border-radius: 10px;
  &--grey {
    #{$root}__body {
      background-color: $neutral-50;
    }
  }
  &--white {
    #{$root}__body {
      background-color: $white;
    }
  }

  &--disabled {
    #{$root}__header-label {
      color: $neutral-400;
    }
  }

  &--default:focus-within {
    #{$root}__body {
      outline: 1px solid $primary-500;
    }
  }
  &--error {
    #{$root}__body {
      outline: 1px solid $error-500;
    }
    #{$root}__footer {
      color: $error-500;
    }
  }
  &--success {
    #{$root}__body {
      outline: 1px solid $success-500;
    }
    #{$root}__footer {
      color: $success-500;
    }
  }
  &--warning {
    #{$root}__body {
      outline: 1px solid $warning-500;
    }
    #{$root}__footer {
      color: $warning-500;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
  }
  &__body {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px 12px;
    gap: 8px;
    border-radius: 10px;
  }
  &__footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-top: 4px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: $neutral-500;
  }

  &__header-label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: $neutral-900;
  }
  &__header-hint {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: $neutral-500;
  }

  &__input-box {
    border: 0;
    outline: 0;
    resize: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    background: transparent;
    width: 100%;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__footer-icon {
    margin-right: 4px;
    height: 20px;
    line-height: 20px;
  }
}
