@import '@julaya/common/scss/system-variables';

$spinners-map: (
  'xs': (
    'size': 1rem
  ),
  'sm': (
    'size': 1.25rem
  ),
  'md': (
    'size': 2rem
  ),
  'lg': (
    'size': 2.5rem
  ),
  'xl': (
    'size': 3rem
  )
);

@keyframes jla-spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.jla-spinner-wrapper {
  $self: &;
  display: flex;
  align-items: center;

  &--vertical {
    flex-direction: column;
    text-align: center;
  }

  &--horizontal {
    flex-direction: row;
  }
}

.jla-spinner {
  display: inline-block;
  width: map-get(map-get($spinners-map, 'sm'), 'size');
  height: 20px;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: jla-spinner-border 0.75s linear infinite;

  &--horizontal {
    margin-right: $spacing;
    flex-shrink: 0;
  }

  &--vertical {
    margin-bottom: $spacing;
  }

  @each $size, $data in $spinners-map {
    &--#{$size} {
      width: map-get($data, 'size');
      height: map-get($data, 'size');
    }
  }

  @each $name, $color in $theme-colors {
    &--#{$name} {
      border-color: $color;
      border-right-color: transparent;
    }
  }
}
