@import '@julaya/common/scss/system-variables';

%bg-contain {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.jla-sidebar {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 400;
  min-width: 0;
  max-width: 5rem;
  transition: 0.3s min-width ease-in-out;
  max-height: 100%;
  $root: &;
  padding: 12px;
  gap: 8px;
  background: transparent;

  .jla-bullet {
    &__content {
      display: none;
    }
  }

  /*
	|
	| Open
	| -------
	*/
  &--open {
    min-width: 245px !important;
    max-width: 245px !important;

    #{$root} {
      &__footer-copyright {
        overflow: visible;
        min-width: 60% !important;
        &--img {
          opacity: 1 !important;
          transform: translateX(0) !important;
        }
      }
    }

    .jla-bullet {
      &__content {
        display: block;
      }
    }
  }

  &__body {
    display: flex;
    flex-flow: column;
    gap: 16px;
    flex-grow: 1;
    overflow: auto;
  }

  &__footer {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    height: 60px;
    display: flex;
  }
  &__footer-branch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  &__footer-copyright {
    overflow: hidden;
    width: 0;
    min-width: 0;
    transition: all 0.5s;

    &--img {
      @extend %bg-contain;
      height: 20px;
      width: 80px;
      opacity: 0;
      transform: translateX(-0.6rem);
      transition: all 0.3s 0.3s;
    }
  }
}
