%closed {
  .name {
    position: relative;
    display: inline-block;

    &:before {
      content: '';
      position: absolute;

      left: 0;
      top: 50%;

      height: 1px;
      width: 100%;

      background: $black;
    }
  }
}

.status-column {
  @extend .d-flex, .flex-column;

  &.isEmpty {
    .status-columns-body {
      @extend .rounded;
      flex: 0;

      background: rgba($gray-600, 0.1);
    }
  }

  /*
  |
  | Status waiting
  | ---------------------
  */
  &.waiting {
    .tasks-card {
      &:before {
        background: $bg-grey !important;
      }
    }
  }

  /*
  |
  | Status success
  | -----------------
  */
  &.status-success {
    .tasks-card {
      opacity: 0.4;
    }
    .task-hr,
    .task-validator {
      display: none;
    }
  }

  &.status-success,
  &.status-replay {
    .tasks-card {
      .tasks-people {
        display: none !important;
      }
    }
  }

  /*
  |
  | Header
  | -------
  */
  .status-columns-header {
    @extend .d-flex, .justify-content-between, .align-items-center, .pt-3, .mt-3;

    transform: translateY(-1rem);

    transition: 0.5s all;

    .pill-status {
      @extend .rounded, .font-weight-bold;
      height: 50px;
      display: flex;
      align-items: center;
      padding: 5px 15px !important;
    }
  }

  /*
  |
  | Body
  | -------
  */
  .status-columns-body {
    flex: 1;

    .tasks-card:not(:last-child) {
      @extend .mb-3;
    }
  }
}
