// ***
// * SCSS

@import '@julaya/common/scss/system-variables';
@import '@julaya/common/scss/system';

//base
$jla-select-font-family: $font-family-base;
$jla-select-font-weight: 500;
$jla-select-font-size: $font-size-base;
$jla-select-icon-font-size: 1.25rem;
$jla-select-color: $neutral-900;
$jla-select-placeholder-color: $neutral-400;
$jla-select-background-color: $neutral-50;
$jla-select-disabled-color: $neutral-400;

$jla-select-padding-x: 12px;
$jla-select-padding-y: 10px;
$jla-select-border: 1px solid transparent;
$jla-select-border-radius: 10px;

//focus
$jla-select-focus-border: 1px solid $primary-500;

$jla-select-feedback-error-color: $error-500;
$jla-select-feedback-success-color: $success-500;
$jla-select-feedback-warning-color: $warning-500;

$jla-select-body-bg: $white;
$jla-select-body-border: 1px solid $neutral-200;
$jla-select-body-border-radius: 0.625rem;
$jla-select-body-max-height: 230px;

// wrapper
.jla-select {
  $self: &;
  position: relative;

  @include form-input-common(
    $self,
    $jla-select-focus-border,
    $jla-select-placeholder-color,
    $jla-select-disabled-color,
    $jla-select-feedback-success-color,
    $jla-select-feedback-warning-color,
    $jla-select-feedback-error-color
  );

  // input field
  &__input {
    position: relative;
    border: $jla-select-border;
    font-family: $jla-select-font-family;
    font-weight: $jla-select-font-weight;
    font-size: $jla-select-font-size;
    color: $jla-select-color;
    padding: $jla-select-padding-y $jla-select-padding-x;
    border: $jla-select-border;
    background-color: $jla-select-background-color;
    border-radius: $jla-select-border-radius;
    line-height: 1.25;
    width: 100%;
    text-align: left;

    &--prefix {
      padding-left: 40px;
    }
    &--suffix {
      padding-right: 40px;
    }
  }

  &__placeholder {
    color: $neutral-400;
    &--hidden {
      opacity: 0;
    }
  }

  // input prefix and suffix icons
  &__prefix,
  &__suffix {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: $jla-select-icon-font-size;
    top: 0;
    bottom: 0;
    color: $jla-select-color;
  }

  &__prefix {
    padding-left: $jla-select-padding-x;
    left: 0;
  }
  &__suffix {
    padding-right: $jla-select-padding-x;
    right: 0;
  }

  &__body {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    background-color: $jla-select-body-bg;
    border: $jla-select-body-border;
    border-radius: $jla-select-body-border-radius;
    padding: 0.5rem;
    z-index: 10;
    max-height: $jla-select-body-max-height;
    overflow-y: auto;
  }

  &--open {
    #{$self}__body {
      display: block;
    }
  }

  &--top {
    #{$self}__body {
      bottom: 107%;
    }
  }

  &--bottom {
    #{$self}__body {
      top: 107%;
    }
  }

  &__item {
    display: flex;
    padding: 0.5rem;
    align-items: center;
    justify-content: space-between;
    border-radius: $jla-select-body-border-radius;
    color: $neutral-900;

    &--disabled {
      opacity: 0.7;
    }

    &:hover:not(&--disabled) {
      background-color: $neutral-200;
    }
  }
  &__item-value {
    margin-left: 0.5rem;
  }
  &__item-radio {
    display: flex;
    align-items: center;
  }
}
