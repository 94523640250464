// ***
// * SCSS

@import '@julaya/common/scss/system-variables';

// AVATARS

$avatar-size-xs: 1.5rem;
$avatar-radius-xs: 0.375rem;
$avatar-font-xs: 0.75rem;
$avatar-icon-size-xs: 1.25rem;

$avatar-size-sm: 2rem;
$avatar-radius-sm: 0.5rem;
$avatar-font-sm: 0.75rem;
$avatar-icon-size-sm: 1.25rem;

$avatar-size-md: 2.5rem;
$avatar-radius-md: 0.625rem;
$avatar-font-md: 0.75rem;
$avatar-icon-size-md: 1.25rem;

$avatar-size-lg: 3rem;
$avatar-radius-lg: 0.625rem;
$avatar-font-lg: 0.875rem;
$avatar-icon-size-lg: 1.5rem;

$avatar-size-xl: 3.5rem;
$avatar-radius-xl: 0.625rem;
$avatar-font-xl: 1.25rem;
$avatar-icon-size-xl: 1.5rem;

$avatar-size-huge: 4rem;
$avatar-radius-huge: 0.625rem;
$avatar-font-huge: 1.25rem;
$avatar-icon-size-huge: 2rem;

$avatars-map: (
  'xs': (
    'size': $avatar-size-xs,
    'radius': $avatar-radius-xs,
    'font': $avatar-font-xs,
    'icon-size': $avatar-icon-size-xs
  ),
  'sm': (
    'size': $avatar-size-sm,
    'radius': $avatar-radius-sm,
    'font': $avatar-font-sm,
    'icon-size': $avatar-icon-size-sm
  ),
  'md': (
    'size': $avatar-size-md,
    'radius': $avatar-radius-md,
    'font': $avatar-font-md,
    'icon-size': $avatar-icon-size-md
  ),
  'lg': (
    'size': $avatar-size-lg,
    'radius': $avatar-radius-lg,
    'font': $avatar-font-lg,
    'icon-size': $avatar-icon-size-lg
  ),
  'xl': (
    'size': $avatar-size-xl,
    'radius': $avatar-radius-xl,
    'font': $avatar-font-xl,
    'icon-size': $avatar-icon-size-xl
  ),
  'huge': (
    'size': $avatar-size-huge,
    'radius': $avatar-radius-huge,
    'font': $avatar-font-huge,
    'icon-size': $avatar-icon-size-huge
  )
);

$avatar-colors-map: (
  'primary': (
    'bg': $primary-500,
    'color': $white
  ),
  'light': (
    'bg': $primary-50,
    'color': $primary-500
  ),
  'gray': (
    'bg': $neutral-100,
    'color': $neutral-500
  )
);

.jla-avatar {
  $self: &;

  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  text-align: center;
  vertical-align: middle;

  width: map-get(map-get($avatars-map, 'md'), 'size');
  height: map-get(map-get($avatars-map, 'md'), 'size');
  border-radius: map-get(map-get($avatars-map, 'md'), 'radius');

  &__initials,
  &__icon {
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__initials {
    font-size: map-get(map-get($avatars-map, 'md'), 'font');
  }
  &__icon {
    font-size: map-get(map-get($avatars-map, 'md'), 'icon-size');
  }

  @each $size, $data in $avatars-map {
    &.jla-avatar--#{$size} {
      width: map-get($data, 'size');
      height: map-get($data, 'size');
      border-radius: map-get($data, 'radius');
      #{$self}__initials {
        font-size: map-get($data, 'font');
      }
      #{$self}__icon {
        font-size: map-get($data, 'icon-size');
      }
    }
  }

  @each $color, $data in $avatar-colors-map {
    &.jla-avatar--#{$color} {
      background-color: map-get($data, 'bg');
      color: map-get($data, 'color');
    }
  }
}
