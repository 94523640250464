@import '@julaya/common/scss/system-variables';
@import '@julaya/common/scss/system';

$jla-modal-lg: 1200px;
$jla-modal-md: 900px;
$jla-modal-sm: 600px;
$jla-modal-xs: 400px;

$jla-modal-border-radius: 0.625rem;

.jla-modal {
  $self: &;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  // display: none;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;

  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out 0.3s;

  &--open {
    opacity: 1;
    visibility: visible;
    #{$self}__content {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
  &--backdrop-anim {
    #{$self}__dialog {
      transform: scale(1.02);
    }
  }

  &__overlay {
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__dialog {
    width: 500px;
    height: calc(100% - 3.5rem);
    margin: 1.75rem auto;

    &--centered {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--lg {
      width: $jla-modal-lg;
      max-width: $jla-modal-lg;
    }
    &--md {
      width: $jla-modal-md;
      max-width: $jla-modal-md;
    }
    &--sm {
      width: $jla-modal-sm;
      max-width: $jla-modal-sm;
    }
    &--xs {
      width: $jla-modal-xs;
      max-width: $jla-modal-xs;
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
    border-radius: $jla-modal-border-radius;
    overflow: hidden;

    opacity: 0;
    transform: translate(0, -1.75rem);
    transition: 320ms transform ease-in-out;

    &--overflow-visible {
      overflow: visible;
      > div {
        &:first-child {
          border-top-right-radius: $jla-modal-border-radius;
          border-top-left-radius: $jla-modal-border-radius;
        }
        &:last-child {
          border-bottom-right-radius: $jla-modal-border-radius;
          border-bottom-left-radius: $jla-modal-border-radius;
        }
      }
    }
  }
}
