.badge {
  display: inline-block;
  padding: 0.5em;
  font-size: 40%;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &.badge-pill {
    border-radius: 50%;
  }

  &.badge-primary {
    color: #fff;
    background-color: $primary;
  }
  &.badge-warning {
    color: #fff;
    background-color: $warning;
  }
  &.badge-success {
    color: #fff;
    background-color: $success;
  }
  &.badge-danger {
    color: #fff;
    background-color: $danger;
  }
  &.badge-white {
    color: #fff;
    background-color: $white;
  }
}

.documents-doclist {
  p {
    padding: 0;
    margin: 0;
    font-weight: bold;
    position: relative;
    padding-left: 0.7rem;
    &:before {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      content: "•";
      font-size: 10px;
    }
  }
}

.document-comment {
  &.danger {
    color: darken($danger, 1);
    background-color: rgba($danger, 0.2);
  }
  &.success {
    color: darken($success, 1);
    background-color: rgba($success, 0.2);
  }
  &.warning {
    color: darken($warning, 1);
    background-color: rgba($warning, 0.2);
  }
  &.grey {
    color: $text-muted;
    background-color: rgba(#000, 0.2);
  }
}

.loaded-img-preview {
  width: 40vw !important;
  height: 40vw !important;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.loaded-receipt-preview {
  width: 40vw !important;
  height: 40vw !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.loaded-doc-preview {
  position: relative;
  .ext {
    position: absolute;
    top: 65%;
    left: 25%;
    font-size: 1rem;
  }
  i {
    font-size: 30vw;
  }
}
