@import '@julaya/common/scss/system-variables';

$base-size: 1rem;
$border-radius: 0.625rem;

$card-map: (
  'wallet': (
    'img-bg': $primary-100,
    'bg': $primary-50
  ),
  'creditcard': (
    'img-bg': $warning-100,
    'bg': $warning-50
  ),
  'user': (
    'img-bg': $purple-100,
    'bg': $purple-50
  ),
  'beneficiary': (
    'img-bg': $blue-100,
    'bg': $blue-50
  )
);

@mixin card-variant($self, $variant, $vals) {
  &--#{$variant} {
    border-color: map-get($vals, 'img-bg');
    background-color: map-get($vals, 'img-bg');

    #{$self}__content {
      background-color: map-get($vals, 'bg');
    }
  }
}

.jla-marketing-card {
  $self: &;
  border-radius: 0.625rem;
  border-width: 2px;
  border-style: solid;
  display: flex;
  width: 25rem !important;
  height: 10.625rem !important;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;

  @each $variant, $vals in $card-map {
    @include card-variant($self, $variant, $vals);
  }

  #{$self}__title {
    margin-bottom: 0.5rem;
  }

  #{$self}__img {
    width: 8.125rem;
    flex-basis: 8.125rem;
    flex-grow: 0;
    flex-shrink: 0;
    height: 100% !important;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  #{$self}__content {
    flex-grow: 1;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
