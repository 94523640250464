@import '@julaya/common/scss/system-variables';
@import '@julaya/common/scss/fonts';

$jla-note-border-radius: 0.625rem;
$jla-note-padding: $spacing;

$colors-map: (
  'primary': (
    'bg': $primary-50,
    'border': $primary-100,
  ),
  'success': (
    'bg': $success-50,
    'border': $success-100,
  ),
  'error': (
    'bg': $error-50,
    'border': $error-100,
  ),
  'warning': (
    'bg': $warning-50,
    'border': $warning-100,
  ),
  'grey': (
    'bg': $neutral-50,
    'border': $neutral-100,
  ),
);

.jla-note {
  display: flex;
  flex-direction: column;
  border-radius: $jla-note-border-radius;

  @each $color, $data in $colors-map {
    &--#{$color} {
      background-color: map-get($data, 'bg');
    }
  }

  &--vertical {
    display: flex;
    flex-flow: column;
  }
  &--horizontal {
    display: flex;
    flex-flow: row;
    gap: 12px;
  }
}
.jla-note-header {
  display: flex;
  padding: $jla-note-padding;
  border-bottom: 1.5px solid;
  @each $color, $data in $colors-map {
    &--#{$color} {
      border-color: map-get($data, 'border');
    }
  }
}
.jla-note-body {
  padding: $jla-note-padding;

  & > div {
    &:not(:last-child) {
      margin-bottom: $spacing;
    }
  }
}
