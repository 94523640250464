@import '@julaya/common/scss/system-variables';
@import '@julaya/common/scss/system';

@keyframes slideInBottom {
  0% {
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -o-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(calc(100vh - 100%));
    -moz-transform: translateY(calc(100vh - 100%));
    -o-transform: translateY(calc(100vh - 100%));
    transform: translateY(calc(100vh - 100%));
  }
}

.jla-drawer {
  $self: &;
  position: fixed;
  z-index: 9;

  top: 0;
  right: 0;

  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  opacity: 0;
  visibility: hidden;
  transition: 0.3s all;

  &--open {
    opacity: 1;
    visibility: visible;

    animation-name: fadeIn;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-duration: 0.3s;

    #{$self}__inner {
      transform: translateX(0);

      animation-name: slideInRight;
      animation-iteration-count: 1;
      animation-timing-function: ease-in-out;
      animation-duration: 0.3s;
    }

    #{$self}__overlay {
      opacity: 1;
      visibility: visible;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;

    overflow-y: hidden;

    max-width: 40rem;
    width: 40rem;
    height: 100vh;

    background: $white;
    border-left: 1px solid $neutral-300;
    box-shadow: 0px 2px 14px 0px rgba($black, 0.1);

    transform: translateX(100%);
    transition: all 0.3s ease-in-out;
  }

  &__overlay {
    position: absolute;
    z-index: -1;

    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    background: rgba(0, 0, 0, 0.2);

    opacity: 0;
    visibility: hidden;
    transition: 0.3s all;
    transition-delay: 0.3s;
  }

  &--mobile {
    &.jla-drawer--open {
      #{$self}__inner {
        transform: translateY(calc(100vh - 100%));
        animation-name: slideInBottom;
      }

      #{$self}__overlay {
        opacity: 1;
        visibility: visible;
      }
    }

    #{$self}__inner {
      max-width: 100vw;
      width: 100vw;
      max-height: calc(100vh - 32px);

      border: 0;
      border-radius: 10px 10px 0 0;
      box-shadow: 0px 2px 14px 0px rgba($black, 0.1);

      transform: translateY(100%);
      transition: all 0.3s ease-in-out;
    }
  }
}
