.reset {
  height: 100%;

  &__link {
    color: $black;
    text-decoration: underline;
  }

  &__warning {
    background: rgba($red, 0.1);
    border: 1px solid $red;
    padding: 1.2rem;
    border-radius: 0.625rem;
  }

  &__back {
    i {
      margin-right: 1rem;
    }

    cursor: pointer;
    font-size: 0.875rem;
  }

  &__digits {
    display: inline-flex;
    position: relative;

    &-item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 5rem;
      width: 5rem;
      margin-right: 0.625rem;
      background: $bg-grey;
      font-size: 2rem;
      border-radius: 1rem;
      cursor: pointer;
      transition: 0.3s all;

      &.active {
        border: 1px solid $primary;
      }
    }

    .wrap {
      position: absolute;
      display: flex;
      width: 100%;
      height: 100%;
    }

    &-input {
      position: absolute;
      width: 100%;
      border: none;
      font-size: 32px;
      text-align: center;
      background-color: transparent;
      outline: none;
      margin-right: 0.625rem;
      top: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
    }
  }
}
