@import '@julaya/common/scss/system-variables';
@import '@julaya/common/scss/css/core/icons';

.jla-company-info {
  $root: &;
  display: flex;
  justify-content: center;
  position: relative;

  .jla-bullet {
    flex: 1;
    &__content {
      padding: 4px 0;
      .jla-text {
        color: $neutral-900;
      }
    }
  }

  &__header-profile {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 8px 0 8px 12px;

    &--open {
      i {
        transform: rotate(-180deg);
      }
    }
  }
  &__avatar {
    background-size: cover;
    background-position: center;
    height: 2.5rem !important;
    width: 2.5rem !important;
    border-radius: 0.625rem;
    max-width: 2.5rem;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 2.5rem;
  }
  &__no-bg {
    height: 2.5rem !important;
    width: 2.5rem !important;
    border-radius: 0.625rem;
    max-width: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 2.5rem;
    background-color: $primary-500 !important;
  }

  &__info-action {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      transition: 300ms transform ease-in-out;
      font-size: 20px;
    }
    display: none;

    &--open {
      display: flex;
    }
  }

  &__dropdown {
    position: absolute;
    width: 13.75rem;
    margin-top: 4px;
    z-index: 2;
    background: $white;
    box-shadow: 0px 2px 14px 0px rgba($black, 0.1);
    border-radius: 10px;
    overflow: hidden;

    opacity: 0;
    visibility: hidden;
    top: 100%;
    transition: all 300ms ease-in-out;

    &--open {
      visibility: visible;
      opacity: 1;

      animation-name: slideUp;
      animation-iteration-count: 1;
      animation-timing-function: ease-in-out;
      animation-duration: 300ms;
    }
  }
}
