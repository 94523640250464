.layout {
  height: 100%;
  max-height: calc(100vh);
  display: flex;
  flex-direction: column;
  .header-bar {
    display: flex;
    height: 4rem !important;
    min-height: 4rem !important;
    max-height: 4rem !important;
    z-index: 999;
    background-color: $primary;
    justify-content: space-between;
    color: $white;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    .header-left,
    .header-right {
      display: flex;
      align-items: center;
      text-align: center;
    }
    .header-left {
      a {
        display: flex;
        align-items: center;
        text-align: center;
        i {
          color: $white;
          @extend .mr-3;
        }
      }
      .title {
        font-size: 1.2rem;
      }
    }
  }
  .app-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-x: hidden;
    background-color: $bg-grey;
  }
  .page-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 1.5rem;
    padding-top: 1rem;
    background-color: $bg-grey;
  }
  .main-menu {
    background-color: $white;
    display: flex;
    justify-content: center;
    height: 5rem !important;
    min-height: 5rem !important;
    max-height: 5rem !important;
    .menu-item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;

      i {
        @extend .h3, .mb-1, .font-weight-normal;
      }

      &.active {
        color: $secondary;
      }
    }
  }
}
