@import '@julaya/common/scss/system-variables';

.jla-sidebar-admin {
  $root: &;
  background: $primary-500;
  color: $white;

  &--open {
    #{$root}__name-container {
      min-width: 100% !important;
    }
    #{$root}__name {
      opacity: 1 !important;
      transform: translateX(0) !important;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    color: $white;
  }
  &__label-icon {
    margin-right: 8px;
  }

  &__container {
    margin: 0 4px 4px;
    border-left: 2px solid $white;
    padding-left: 12px;
  }

  &__name-container {
    overflow: hidden;
    width: 0;
    transition: all 0.3s;
  }
  &__name {
    position: relative;
    display: block;
    margin-left: 0.5rem;
    min-width: 10.5rem;
    opacity: 0;
    transform: translateX(-0.6rem);
    font-size: 12px;
    line-height: 20px;
  }

  &__nav-link-inner {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.7rem;
    width: 100%;
    border-radius: 0.625rem;
    overflow: hidden;
    i {
      font-size: 1.125rem;
      transition: 0.3s all;
    }
  }

  &__nav-link {
    position: relative;
    margin-bottom: 3px;
    font-weight: 700;
    line-height: 1.2;
    padding: 0;
    transition: 0.3s all ease;
    color: $primary-200;

    &:hover {
      #{$root}__nav-link-inner {
        background: $primary-400;
        color: $primary-200;
      }
    }

    &--active {
      #{$root}__nav-link-inner {
        background: $primary-600;
        color: $white;
      }
    }
  }

  .jla-accordion {
    &__action-btn {
      background: transparent !important;
      i {
        color: white;
      }
    }
    &__body-children {
      margin: 0 !important;
      padding: 0 20px 12px !important;
      &--default-bg {
        background-color: transparent;
      }
    }
    &__body-inner {
      margin: 0;
      padding: 0 !important;
    }
  }
}
