.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

%bg-contain {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

%bg-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
