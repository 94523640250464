/* *********************************** */
/* Variables & all variables overrides needed */
/* ***** */
@import '@julaya/common/scss/css/vendors/bootstrap/bootstrap-functions';
@import './bootstrap-variables-override'; // ! Should be only few lines

/* *********************************** */
/* System css & "app" overrides */
/* ***** */
@import '@julaya/common/scss/system';
@import 'app-css/index.scss'; // ! Should be empty or just few lines

.modal-app-container {
  .avatar-img {
    background-color: #f3f2ff !important;
    color: #5856c6 !important;
  }

  h6 {
    font-size: 20px;
    text-align: center;
  }

  button {
    background: black;
    color: white;
  }
}
