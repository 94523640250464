@import '@julaya/common/scss/system-variables';

.pagination {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background: $white;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 0.3125rem;
  &--left {
    justify-content: flex-start;
  }
  &--right {
    justify-content: flex-end;
  }
  &--center {
    justify-content: center;
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__page-item {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;

    i {
      font-weight: 300;
      color: $gray-600;
    }
    $p: &;
    &:not(&--active):hover {
      background: $gray-300;
    }
    &--active {
      color: $white;
      background: $carbon;
    }
    &--disabled {
      cursor: not-allowed;
      &:hover {
        background: transparent;
      }
    }
  }

  &__table-bar-description {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    margin-right: 1rem;
  }

  &__table-bar-action {
    display: flex;
  }

  &__table-bar-item {
    background: $neutral-100;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    i {
      font-weight: 300;
      color: $gray-600;
    }
    &:hover {
      background: $gray-300;
    }

    &--next {
      border-radius: 10px 0px 0px 10px;
    }
    &--prev {
      border-radius: 0px 10px 10px 0px;
    }
  }
}
