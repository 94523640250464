.jla-table-filter {
  display: flex;
  justify-content: space-between;
  & > div {
    display: flex;
  }
}

.jla-table-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  height: 100%;

  .jla-table-no-result {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    align-items: center;
    text-align: center;
  }

  .jla-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 8px;
    padding-bottom: 2rem;

    th,
    td {
      &:last-child {
        padding-right: 10px;
      }
      padding-left: 10px;
      padding-top: 9px;
      padding-bottom: 9px;
    }
  }

  .pagination {
    width: 100%;
    position: sticky;
    bottom: 20px;
  }
}
