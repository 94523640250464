$lbase-size: 50% !default;
.border-bottom-tab {
  border-bottom: 2px solid rgba(255, 255, 255, 0.4) !important;
}
.login-switch {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;

  .left,
  .right {
    font-weight: bold;
    display: inline-block;
    width: $lbase-size;
    z-index: 1;
    text-align: center;
    transition: all 0.4s;
  }
  .left {
    color: $white;
  }
  .right {
    color: rgba(255, 255, 255, 0.6);
  }

  &:before {
    content: "";
    position: absolute;
    transition: all 0.4s;
    background-color: $white;
    width: $lbase-size;
    height: 2px;
    left: 0;
    bottom: -2px;
  }
  &.on {
    &:before {
      left: $lbase-size;
    }
    .left {
      color: rgba(255, 255, 255, 0.7);
    }
    .right {
      color: $white;
    }
  }
}

$base-size: 25vw !default;
.subscription-switch {
  width: $base-size * 2;
  display: flex;
  background-color: $white;
  border-radius: 12.5vw;
  position: relative;

  .left,
  .right {
    display: inline-block;
    width: $base-size;
    z-index: 1;
    text-align: center;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
  .left {
    color: $white;
  }
  .right {
    color: $text-muted;
  }

  &:before {
    content: "";
    position: absolute;
    transition: all 0.4s;
    background-image: linear-gradient(-45deg, $secondary 0%, $primary 100%);
    border-radius: 12.5vw;
    width: $base-size;
    left: 0;
    top: 0;
    bottom: 0;
  }
  &.on {
    .left {
      color: $text-muted;
    }
    .right {
      color: $white;
    }
    &:before {
      left: $base-size;
      top: 0;
      bottom: 0;
    }
  }
}
