// ***
// * SCSS

@import '@julaya/common/scss/system-variables';
@import '@julaya/common/scss/system';

//base
$jla-text-font-family: $font-family-base;
$jla-text-font-weight: 500;
$jla-text-font-size: $font-size-base;
$jla-text-icon-font-size: 1.25rem;
$jla-text-color: $neutral-900;
$jla-text-placeholder-color: $neutral-400;
$jla-text-background-color: $neutral-50;
$jla-text-disabled-color: $neutral-400;

$jla-text-padding-x: 12px;
$jla-text-padding-y: 10px;
$jla-text-border: 1px solid transparent;
$jla-text-border-radius: 10px;

//focus
$jla-text-focus-border: 1px solid $primary-500;

$jla-text-feedback-error-color: $error-500;
$jla-text-feedback-success-color: $success-500;
$jla-text-feedback-warning-color: $warning-500;

// wrapper
.jla-text {
  $self: &;
  position: relative;

  @include form-input-common(
    $self,
    $jla-text-focus-border,
    $jla-text-placeholder-color,
    $jla-text-disabled-color,
    $jla-text-feedback-success-color,
    $jla-text-feedback-warning-color,
    $jla-text-feedback-error-color
  );

  // input field
  &__input {
    border: $jla-text-border;
    font-family: $jla-text-font-family;
    font-weight: $jla-text-font-weight;
    font-size: $jla-text-font-size;
    color: $jla-text-color;
    padding: $jla-text-padding-y $jla-text-padding-x;
    border: $jla-text-border;
    background-color: $jla-text-background-color;
    border-radius: $jla-text-border-radius;
    line-height: 1.25;
    width: 100%;

    &--prefix {
      padding-left: 40px;
    }
    &--suffix {
      padding-right: 40px;
    }

    // Change autocomplete styles in Chrome
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0px 1000px $jla-text-background-color inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  // input prefix and suffix icons
  &__prefix,
  &__suffix {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: $jla-text-icon-font-size;
    top: 0;
    bottom: 0;
    color: $jla-text-color;
  }

  &__prefix {
    padding-left: $jla-text-padding-x;
    left: 0;
  }
  &__suffix {
    padding-right: $jla-text-padding-x;
    right: 0;
  }
}
