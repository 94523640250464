@import '@julaya/common/scss/system-variables';

@mixin position($pos1, $pos2) {
  @if $pos1 == 'top' or $pos1 == 'bottom' {
    @if $pos2 == 'middle' {
      right: 50%;
      transform: translateX(50%);
    } @else if $pos2 == 'end' {
      left: 5%;
    } @else if $pos2 == 'start' {
      right: 5%;
    }
  }

  @if $pos1 == 'left' or $pos1 == 'right' {
    @if $pos2 == 'middle' {
      bottom: 50%;
      transform: translateY(50%);
    } @else if $pos2 == 'end' {
      top: 5%;
    } @else if $pos2 == 'start' {
      bottom: 5%;
    }
  }
}

@mixin position-adj($pos1, $pos2) {
  @if $pos1 == 'top' or $pos1 == 'bottom' {
    @if $pos2 == 'end' {
      left: -50%;
    } @else if $pos2 == 'start' {
      right: -50%;
    }
  }
}

// trigger
$jla-tooltip-color: $neutral-500;
$jla-tooltip-hover-color: $neutral-700;
$jla-tooltip-size: 1.25rem;

// body
$jla-tooltip-width: 17.5rem;
$jla-tooltip-padding: 1rem;
$jla-tooltip-border-radius: 0.625rem;
$jla-tooltip-bg: $white;
$jla-tooltip-close-size: 1.25rem;
$jla-tooltip-close-color: $neutral-900;

$jla-tooltip-arrow: 0.4rem;
$jla-tooltip-arrow-position: 0.3125rem;

$placements-map-1: (
  'top': 'bottom',
  'bottom': 'top',
  'left': 'right',
  'right': 'left'
);

$placements-map-2: (
  'start': 100%,
  'middle': 100%,
  'end': 100%
);

.jla-tooltip {
  $self: &;
  position: relative;

  &--open,
  &:hover {
    #{$self}__trigger {
      color: $jla-tooltip-hover-color;
    }
    #{$self}__body {
      pointer-events: all;
      opacity: 1;
    }
  }

  &__trigger {
    cursor: pointer;
    font-size: $jla-tooltip-size;
    color: $jla-tooltip-color;
    transition: all 320ms ease-in-out;
  }

  &__body {
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.4) 0px 1px 2px 0px;
    transition: all 320ms ease-in-out;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    display: inline-flex;
    flex-direction: column;
    padding: $jla-tooltip-padding;
    border-radius: $jla-tooltip-border-radius;
    background-color: $jla-tooltip-bg;
    width: $jla-tooltip-width;
  }

  @each $placement-1, $placementValue-1 in $placements-map-1 {
    @each $placement-2, $placementValue-2 in $placements-map-2 {
      &--#{$placement-1}-#{$placement-2} {
        #{$self}__body {
          #{$placementValue-1}: 107%;
          @include position($placement-1, $placement-2);
          @include position-adj($placement-1, $placement-2);
        }
        #{$self}__arrow {
          @include position($placement-1, $placement-2);
        }
      }
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
  }
  &__arrow {
    position: absolute;
    &--left {
      right: -$jla-tooltip-arrow-position;
      border-top: $jla-tooltip-arrow solid transparent;
      border-bottom: $jla-tooltip-arrow solid transparent;
      border-left: $jla-tooltip-arrow solid $jla-tooltip-bg;
    }

    &--right {
      left: -$jla-tooltip-arrow-position;
      border-top: $jla-tooltip-arrow solid transparent;
      border-bottom: $jla-tooltip-arrow solid transparent;
      border-right: $jla-tooltip-arrow solid $jla-tooltip-bg;
    }

    &--bottom {
      top: -$jla-tooltip-arrow-position;
      border-left: $jla-tooltip-arrow solid transparent;
      border-right: $jla-tooltip-arrow solid transparent;
      border-bottom: $jla-tooltip-arrow solid $jla-tooltip-bg;
    }

    &--top {
      bottom: -$jla-tooltip-arrow-position;
      border-left: $jla-tooltip-arrow solid transparent;
      border-right: $jla-tooltip-arrow solid transparent;
      border-top: $jla-tooltip-arrow solid $jla-tooltip-bg;
    }
  }
  &__actions {
    display: flex;
  }
  &__close {
    font-size: $jla-tooltip-close-size;
    color: $jla-tooltip-close-color;
    cursor: pointer;
  }
  &__footer {
    justify-content: flex-end;
    margin-top: 1rem;
    display: flex;
    gap: $spacer / 2;
  }
}
