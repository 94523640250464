%card {
  display: block;
  padding: 1rem 0;
  border-radius: 4px;

  text-align: center;
  font-weight: 700;
  color: $black;

  .transaction-card-icon {
    display: block;
    margin: auto;

    height: 2.4rem;
    width: 2.4rem;

    margin-bottom: 0.3rem;

    @extend %bg-contain;
  }

  .transaction-card-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 0.5rem auto;

    height: 50px;
    width: 50px;
    border-radius: 4px;

    i {
      font-size: 18px;
      color: $white;
    }
  }

  .title {
    font-size: 0.75rem;
  }
}

.mb-15 {
  margin-bottom: 15px;
}

/*
|
| Animations
| -------------
*/
@keyframes fadeInOpacity {
  0% {
    background-color: rgba($black, 0);
  }

  100% {
    background-color: rgba($black, 0.7);
  }
}

.dashboard-actions {
  .card-container {
    /*
    |
    | Card Transfer
    | -----------------
    */
    padding: 3px;

    .transaction-card {
      @extend %card;
    }
  }

  /*
    |
    | Modal
    | --------
    */
  .modal-associates-types-container {
    position: fixed;
    height: 0;
    width: 100vw;
    z-index: 1;

    left: 0;
    bottom: 0;

    background: rgba($black, 0);

    /*
    |
    | Opening Modal
    | --------------
    */
    &.open {
      height: 100vh;
      background: rgba($black, 0.7);

      animation-name: fadeInOpacity;
      animation-iteration-count: 1;
      animation-timing-function: ease-in-out;
      animation-duration: 0.3s;

      .modal-associates-types {
        transform: translateY(0px);
        opacity: 1;
        transition: all 300ms ease-in-out;
      }
    }
  }
  .modal-associates-types {
    background-color: $white;
    border-radius: 8px;

    position: absolute;
    bottom: 0;
    left: 0;

    transform: translateY(100%);
    transform-origin: bottom;
    opacity: 0;
    width: 100%;
    transition: all 300ms ease-in-out;

    .modal-associates-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      padding: 1rem calc(1rem + 15px);
      border-bottom: 1px solid $bg-grey;

      .head-icon {
        height: 40px;
        width: 40px;

        margin-right: 1rem;

        @extend %bg-contain;
      }

      .icon {
        transform: rotate(90deg);
        font-size: 16px;
      }
    }

    .modal-associates-body {
      display: flex;
      flex-wrap: wrap;

      padding: 1rem;

      .transaction-card {
        padding: 1.5rem;

        @extend %card;

        .transaction-card-icon {
          height: 50px;
          width: 50px;
        }
      }
    }
  }
}

.fab {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 1.5rem;
  right: 1.5rem;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3);
  background-color: var(--primary);
  i {
    color: $white;
    font-size: 1.75rem;
  }
}

@each $name, $color in $colorTypes {
  .transaction-card-#{$name} {
    background-color: rgba($color, 0.2);

    .transaction-card-icon-container {
      background-color: $color;
    }
  }
}

.button-container {
  display: flex;
  flex-direction: row;
  position: fixed;
  width: 100%;
  bottom: 1rem;
  padding: 0 1rem;
}

.button-recharge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  right: 13.3rem;
  border-radius: 10px;
  background-color: white;
  color: $carbon;
  border: 1px solid $carbon;
  bottom: 1rem;
  height: 3.5rem;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3);
  margin-right: 0.3rem;
}
.button-transfer {
  display: flex;
  align-items: center;
  height: 3.5rem;
  background-color: var(--primary);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3);
  justify-content: center;
  width: 100%;
  color: white;
  border-radius: 10px;
  bottom: 1rem;
  margin-left: 0.3rem;
}
