@import '@julaya/common/scss/system-variables';

$base-size: 1rem;
$border-radius: 0.625rem;

.jla-stat-cards {
  &__item {
    display: flex;
    flex-grow: 1;
    flex: 1 1 0px;
    padding: $base-size;
    background-color: $white;
    border-radius: $border-radius;
    &--disabled {
      background-color: $neutral-50;
      .jla-stat-cards__value {
        color: $primary-300;
      }
    }
  }

  &__icon {
    background-color: $primary-50;
    border-radius: $border-radius;
    padding: $base-size;
    color: $primary-500;
    font-size: $base-size * 1.2;
    margin-right: $base-size;
    line-height: 0;
  }

  &__value {
    font-weight: 700;
    line-height: 1.75rem;
    color: $primary-500;
    font-size: 1.125rem;
  }
}
