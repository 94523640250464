/*
    |
    | Modal
    | --------
    */
.modal-associates-types-container {
  position: fixed;
  height: 0;
  width: 100vw;
  z-index: 1;

  left: 0;
  bottom: 0;

  background: rgba($black, 0);

  /*
    |
    | Opening Modal
    | --------------
    */
  &.open {
    height: 100vh;
    background: rgba($black, 0.7);

    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-duration: 0.3s;

    .modal-associates-types {
      transform: translateY(0px);
      opacity: 1;
      transition: all 300ms ease-in-out;
    }
  }
}

.modal-associates-types {
  background-color: $white;
  border-radius: 8px;

  position: absolute;
  bottom: 0;
  left: 0;

  transform: translateY(100%);
  transform-origin: bottom;
  opacity: 0;
  width: 100%;
  transition: all 300ms ease-in-out;

  .modal-associates-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    padding: 1rem calc(1rem + 15px);
    border-bottom: 1px solid $bg-grey;

    .head-icon {
      height: 40px;
      width: 40px;

      margin-right: 1rem;

      @extend %bg-contain;
    }

    .icon {
      transform: rotate(90deg);
      font-size: 16px;
    }
  }

  .modal-associates-body {
    display: flex;
    flex-wrap: wrap;

    padding: 1rem;

    .transaction-card {
      padding: 1.5rem;

      @extend %card;

      .transaction-card-icon {
        height: 50px;
        width: 50px;
      }
    }
  }
}
