@import '@julaya/common/scss/system-variables';

.main-link {
  $root: &;
  flex-grow: 1;
  display: flex;
  align-items: center;

  &--open {
    #{$root}__name-container {
      min-width: 100% !important;
    }
    #{$root}__name {
      opacity: 1 !important;
      transform: translateX(0) !important;
    }
  }
  &__wrapper {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
  }
  &__nav-link {
    position: relative;
    font-weight: 700;
    line-height: 1.2;
    padding: 0;
    color: $neutral-700;
    transition: 0.3s all;
    border-radius: 0.625rem;

    &--disabled {
      cursor: not-allowed;
      background-color: $neutral-200;
      pointer-events: none;
      #{$root}__nav-link-inner {
        opacity: 0.6;
      }
    }

    &--active {
      #{$root}__nav-link-inner {
        background: $primary-500;
        color: $white;

        i {
          color: $white;
        }
      }
      #{$root}__name-container {
        color: $white;
      }
    }

    &:hover:not(&--active):not(&--disabled) {
      #{$root}__nav-link-inner {
        background: $neutral-200;
      }
    }
  }

  &__nav-link-inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    padding: 10px 12px;
    width: 100%;
    border-radius: 0.625rem;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      height: 0;
      width: 100%;
      transition: 0.3s all ease;
    }

    i {
      font-size: 20px;
    }
  }

  &__name-container {
    overflow: hidden;
    width: 0;
  }

  &__name {
    position: relative;
    display: block;
    margin-left: 0.5rem;
    min-width: 10.5rem;
    opacity: 0;
    transform: translateX(-0.6rem);
    font-size: 12px;
    line-height: 20px;
  }

  &__link-arrow {
    &:after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;

      font-family: 'julaya';
      content: '\e977';
    }
  }
}
