// ***
// * SCSS

@import '@julaya/common/scss/system-variables';
@import '@julaya/common/scss/system';

//base
$jla-telephone-font-family: $font-family-base;
$jla-telephone-font-weight: 500;
$jla-telephone-select-font-weight: 700;
$jla-telephone-font-size: $font-size-base;
$jla-telephone-icon-font-size: 1.25rem;
$jla-telephone-color: $neutral-900;
$jla-telephone-placeholder-color: $neutral-400;
$jla-telephone-background-color: $neutral-50;
$jla-telephone-disabled-color: $neutral-400;

$jla-telephone-padding-x: 12px;
$jla-telephone-padding-y: 10px;
$jla-telephone-border: 1px solid transparent;
$jla-telephone-border-radius: 10px;

//focus
$jla-telephone-focus-border: 1px solid $primary-500;

$jla-telephone-feedback-error-color: $error-500;
$jla-telephone-feedback-success-color: $success-500;
$jla-telephone-feedback-warning-color: $warning-500;

// wrapper
.jla-telephone {
  $self: &;
  position: relative;

  border-radius: $jla-telephone-border-radius;
  text-overflow: hidden;

  @include form-input-common(
    $self,
    $jla-telephone-focus-border,
    $jla-telephone-placeholder-color,
    $jla-telephone-disabled-color,
    $jla-telephone-feedback-success-color,
    $jla-telephone-feedback-warning-color,
    $jla-telephone-feedback-error-color
  );

  // select field
  &__select {
    font-family: $jla-telephone-font-family;
    font-weight: $jla-telephone-select-font-weight;
    font-size: $jla-telephone-font-size;
    color: $jla-telephone-color;
    border: none;
    background-color: transparent;
    line-height: 1.25;
    border-right: 1px solid $neutral-200;

    appearance: none;
    text-indent: 1px;
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 1;
    padding-left: $jla-telephone-padding-x;
    padding-right: $jla-telephone-padding-x * 2;

    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23171717' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    background-position: right 0.25rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;

    &:focus {
      box-shadow: none;
      outline: none;
    }

    &:disabled {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23a3a3a3' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
      color: $jla-telephone-disabled-color;
    }
  }

  //input field
  &__input {
    border: $jla-telephone-border;
    font-family: $jla-telephone-font-family;
    font-weight: $jla-telephone-font-weight;
    font-size: $jla-telephone-font-size;
    color: $jla-telephone-color;
    padding-top: $jla-telephone-padding-y;
    padding-bottom: $jla-telephone-padding-y;
    padding-right: $jla-telephone-padding-x;
    padding-left: $jla-telephone-padding-x * 9;
    border: $jla-telephone-border;
    background-color: $jla-telephone-background-color;
    border-radius: $jla-telephone-border-radius;
    line-height: 1.25;
    width: 100%;
  }
}
