// * New variables (outside bootstrap usages)

// COLORS

$neutral-50: #fafafa;
$neutral-100: #f5f5f5;
$neutral-200: #e6e6e6;
$neutral-300: #d4d4d4;
$neutral-400: #a3a3a3;
$neutral-500: #737373; //default
$neutral-600: #525252;
$neutral-700: #404040;
$neutral-800: #262626;
$neutral-900: #171717;

$carbon: #292929;

$primary-50: #fff3f0;
$primary-100: #ffd9cc;
$primary-200: #ffc6b3;
$primary-300: #ffab8f;
$primary-400: #ff9c7a;
$primary-500: #ff8157; //default
$primary-600: #e8754f;
$primary-700: #b65c3e;
$primary-800: #8d4730;
$primary-900: #6a3624;

$success-50: #ebfaf1;
$success-100: #baedcd;
$success-200: #9ae4b5;
$success-300: #6ad792;
$success-400: #4dd17d;
$success-500: #21c45d; //default
$success-600: #1fb255;
$success-700: #188c42;
$success-800: #136c34;
$success-900: #0e5327;

$warning-50: #fff7eb;
$warning-100: #ffe6c2;
$warning-200: #ffd9a3;
$warning-300: #ffc67a;
$warning-400: #ffbd61;
$warning-500: #ffac38; //default
$warning-600: #e89c30;
$warning-700: #b57a26;
$warning-800: #8b5d1d;
$warning-900: #694516;

$error-50: #fdedec;
$error-100: #fac4c2;
$error-200: #f8a8a5;
$error-300: #f47c76;
$error-400: #f2665f;
$error-500: #ef3d34; //default
$error-600: #d93630;
$error-700: #aa2d27;
$error-800: #85221e;
$error-900: #641917;

$blue-50: #e6f4ff;
$blue-100: #b2deff;
$blue-200: #8accff;
$blue-300: #52b4ff;
$blue-400: #33a7ff;
$blue-500: #0091ff; //default
$blue-600: #0082e5;
$blue-700: #0065b2;
$blue-800: #004e8a;
$blue-900: #003d6b;

$purple-50: #f4e5ff;
$purple-100: #deb8ff;
$purple-200: #cd94ff;
$purple-300: #b562fe;
$purple-400: #a743fe;
$purple-500: #9116fe; //default
$purple-600: #8411e8;
$purple-700: #670db5;
$purple-800: #4e0a8a;
$purple-900: #3e086d;

$lilas-50: #f3f2ff;
$lilas-100: #edecff;
$lilas-200: #e7e7ff;
$lilas-300: #e0e0ff;
$lilas-400: #d9d9ff;
$lilas-500: #d1d0ff;
$lilas-600: #c0beff;
$lilas-700: #a5a3ff;
$lilas-800: #7674e5;
$lilas-900: #5856c6;

$white: #ffffff;
$black: #000000;

$bg-color: #f6f5f5;

$theme-colors: (
  'neutral': $neutral-500,
  'primary': $carbon,
  'secondary': $lilas-900,
  'success': $success-500,
  'warning': $warning-500,
  'error': $error-500,
  'black': $black,
  'white': $white
);

// FONTS

$font-size-base: 0.875rem;
$font-weight-base: 500;
$lead-font-weight: 700;
$font-family-base: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

$display1-font-size: 3.25rem;
$display1-line-height: 56px;
// $display1-margin-bottom: 0.5rem;
$display2-font-size: 2.75rem;
$display2-line-height: 48px;
// $display2-margin-bottom: 0.5rem;

$h1-font-size: 2.5rem;
$h1-line-height: 48px;
// $h1-font-margin-bottom: 0.5rem;
$h2-font-size: 2.25rem;
$h2-line-height: 44px;
// $h2-font-margin-bottom: 0.5rem;
$h3-font-size: 2rem;
$h3-line-height: 40px;
// $h3-font-margin-bottom: 0.5rem;
$h4-font-size: 1.75rem;
$h4-line-height: 36px;
// $h4-font-margin-bottom: 0.5rem;
$h5-font-size: 1.5rem;
$h5-line-height: 32px;
// $h5-font-margin-bottom: 0.5rem;
$h6-font-size: 1.25rem;
$h6-line-height: 28px;
// $h6-font-margin-bottom: 0.5rem;

$text-font-size-lg: 1.125rem;
$text-line-height-lg: 28px;
// $text-margin-bottom-lg: 0.5rem;
$text-font-size-md: 1rem;
$text-line-height-md: 24px;
// $text-margin-bottom-md: 0.5rem;
$text-font-size-sm: 0.875rem;
$text-line-height-sm: 20px;
// $text-margin-bottom-sm: 0.5rem;
$text-font-size-xs: 0.75rem;
$text-line-height-xs: 20px;
// $text-margin-bottom-xs: 0.5rem;

.text-white-space-pre {
  white-space: pre;
}

// SPACINGs

$spacing: 1rem !default;

$spacings-map: (
  // 0
  'none': 0,
  // 2
  'xxx-sm': $spacing * 0.125,
  // 4
  'xx-sm': $spacing * 0.25,
  // 8
  'x-sm': $spacing * 0.5,
  // 12
  'sm': $spacing * 0.75,
  // 16
  'md': $spacing * 1,
  // 20
  'big': $spacing * 1.25,
  // 24
  'x-big': $spacing * 1.5,
  // 28
  'xx-big': $spacing * 1.75,
  // 32
  'xxx-big': $spacing * 2,
  // 40
  'lg': $spacing * 2.5,
  // 48
  'x-lg': $spacing * 3,
  // 64
  'xx-lg': $spacing * 4,
  // 80
  'xxx-lg': $spacing * 5,
  // 96
  'huge': $spacing * 6,
  // 128
  'x-huge': $spacing * 8,
  // 160
  'xx-huge': $spacing * 10
);
