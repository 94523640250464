@import '@julaya/common/scss/system-variables';

@mixin jla-table-loading__anim-mixin {
  overflow: hidden;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: backgroundAnimation;
  animation-timing-function: linear;
  background: linear-gradient(to right, #e9eaf0 8%, #d5d8e3 18%, #e9eaf0 33%);
  background-size: 800px 104px;
}


.jla-table-loading{
  &__placeholder-line {
    @include jla-table-loading__anim-mixin;
    height: 8px;
    display: block;
    border-radius: 50px;
  }
}