@import '@julaya/common/scss/system-variables';

.profile-menu {
  $root: &;
  &__role {
    background: $primary-50;
    color: $primary-500;
    text-align: center;
    font-weight: 700;
  }
  &__container {
    padding: 16px 8px;
  }
  &__company {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  }
  &__briefcase {
    height: 2.5rem !important;
    width: 2.5rem !important;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__avatar {
    background-size: cover;
    background-position: center;
    height: 2.5rem !important;
    width: 2.5rem !important;
    border-radius: 0.625rem;
  }
  &__name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: $neutral-900;
  }
  &__initials {
    width: 2.5rem;
    height: 2.5rem;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.625rem;
    background-color: $primary-500 !important;
  }
  &__nav-icon {
    width: 1.25rem;
    height: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
  }
  &__nav {
    display: flex;
    flex-direction: column;
  }
  &__nav-item {
    font-weight: 700;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      background-color: $neutral-100;
    }

    &--support {
      color: $neutral-900 !important;
      &:hover {
        color: $neutral-900 !important;
        background-color: $neutral-100;
      }
    }

    &--disconnect {
      color: $error-400;
      &:hover {
        color: $error-500;
        background-color: $neutral-100;
      }
    }
  }
  &__support {
    font-weight: 700;
  }
  &__disconnect {
    margin-bottom: 0.75rem;
  }
  &__divider {
    border-bottom: 2px solid $neutral-100;
    margin: 8px 0;
  }
  #{$root}__nav ~ #{$root}__nav {
    margin-top: 4px;
  }
}
