@import '@julaya/common/scss/system-variables';

.jla-sidebar-operation {
  &__label {
    display: flex;
    align-items: center;
  }
  &__label-icon {
    margin-right: 8px;
  }
  &__container {
    margin: 0 4px 4px;
    border-left: 2px solid $primary-500;
    padding-left: 12px;
  }

  .jla-accordion {
    &__action-btn {
      background: transparent !important;
    }
    &__body-children {
      margin: 0!important;
      padding: 0 20px 12px!important;
    }
    &__body-inner {
      margin: 0;
      padding: 0!important;
    }
  }
}
