.comments {
  .avatar-small {
    @extend %bg-cover;
    height: 2.75rem;
    width: 2.75rem;
    min-width: 2rem;
    border-radius: 8px;
  }
  .form-group {
    flex: 1;
    background: $bg-grey;

    .form-control {
      padding: 0 !important;
      height: auto;
    }

    .btn {
      padding: 0.3rem 0.8rem;
    }
  }
}
