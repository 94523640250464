@import '@julaya/common/scss/system-variables';

.jla-divider {
  display: block;
  width: 100%;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: $black;

  &--dashed {
    border-bottom-style: dashed;
  }

  &--neutral {
    border-bottom-color: $neutral-100;
  }
  &--black {
    border-bottom-color: $black;
  }
}
