// Form input extra

@mixin form-input-common(
  $self,
  $input-focus-border,
  $input-placeholder-color,
  $input-disabled-color,
  $feedback-success-color,
  $feedback-warning-color,
  $feedback-error-color
) {
  //label
  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.875rem;

    label {
      font-weight: 700;
      line-height: 0.75rem;
      color: $neutral-900;
    }

    &--disabled {
      label {
        color: $neutral-400;
      }
    }

    #{$self}__label-hint {
      font-weight: 500;
      line-height: 0.75rem;
      color: $neutral-500;
    }
  }
  // input
  &__input {
    &:focus {
      border: $input-focus-border;
      box-shadow: none;
      outline: none;
    }

    &::placeholder {
      color: $input-placeholder-color;
    }

    &:disabled {
      color: $input-disabled-color;
    }
  }

  // text feedback
  &__feedback {
    margin-top: 0.25rem;
  }

  // text modifiers
  &--bg-white {
    #{$self}__input {
      background-color: $white;
    }
  }

  &--disabled {
    color: $input-disabled-color;
  }

  &--success {
    #{$self}__input {
      border: 1px solid $feedback-success-color;
    }
  }

  &--warning {
    #{$self}__input {
      border: 1px solid $feedback-warning-color;
    }
  }

  &--error {
    #{$self}__input {
      border: 1px solid $feedback-error-color;
    }
  }
}
