@import '@julaya/common/scss/system-variables';

.jla-dropdown {
  $root: &;
  position: relative;

  &--open {
    #{$root}__icon-down {
      transform: rotate(180deg);
    }
    #{$root}__menu-item {
      display: block;
    }
  }
  &--disabled {
    cursor: not-allowed;
    #{$root}__header-label {
      color: $neutral-400;
    }
  }
  &--white {
    #{$root}__body {
      background-color: $white;
    }
  }
  &--grey {
    #{$root}__body {
      background-color: $neutral-50;
    }
  }
  &--default:focus-within {
    #{$root}__body {
      outline: 1px solid $primary-500;
    }
  }
  &--error {
    #{$root}__body {
      outline: 1px solid $error-500;
    }
    #{$root}__footer {
      color: $error-500;
    }
  }
  &--success {
    #{$root}__body {
      outline: 1px solid $success-500;
    }
    #{$root}__footer {
      color: $success-500;
    }
  }
  &--warning {
    #{$root}__body {
      outline: 1px solid $warning-500;
    }
    #{$root}__footer {
      color: $warning-500;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
  }
  &__body {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 6px 12px;
    gap: 8px;
    border-radius: 10px;
    user-select: none;
  }
  &__footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-top: 4px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: $neutral-500;
  }

  &__header-label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: $neutral-900;
  }
  &__header-hint {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: $neutral-500;
  }
  &__input-box {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    background: transparent;
    width: 100%;
    height: 28px;
  }
  &__footer-icon {
    margin-right: 4px;
    height: 20px;
    line-height: 20px;
  }
  &__icon-down {
    transition: 0.15s all;
  }

  &__menu-item {
    position: absolute;
    display: none;
    width: 100%;
    background: $white;
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    padding: 16px;
    z-index: 10;
    margin-top: 8px;
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.06);
    width: 400px;
  }
  &__menu-item-header {
    border-bottom: 1px solid $neutral-100;
    &__reset {
      cursor: pointer;
    }
  }
  &__menu-item-footer {
    border-top: 1px solid $neutral-100;
  }
  &__value-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    flex: 1;
    overflow: auto;
  }
  &__value-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: $neutral-100;
    border-radius: 8px;
    gap: 8px;
    padding: 6px;
  }
  &__value-label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: $neutral-900;
  }
  &__value-icon {
    font-size: 16px;
    cursor: pointer;
  }

  &__menu-item-header,
  &__menu-item-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
  }
  &__menu-item-header {
    padding-bottom: 16px;
  }
  &__header-box {
    display: flex;
    align-items: center;
    padding: 10;
    gap: 8px;
    &--left {
      justify-content: flex-start;
    }
    &--right {
      justify-content: flex-end;
    }
    &--pointer {
      cursor: pointer;
    }
  }

  &__menu-item-footer {
    padding-top: 16px;
  }
  &__footer-box {
    flex-grow: 1;
    & > * {
      width: 100%;
    }
    button {
      justify-content: center;
    }
  }

  &__menu-item-body {
    padding: 8px 0;
    display: flex;
    flex-flow: column;
    gap: 14px;
  }
}
