.tasks-card {
  position: relative;
  color: $black;
  @extend .rounded, .p-3, .d-flex, .flex-column, .bg-white;
  box-shadow: 0 2px 14px 0 rgba($black, 0.05);
  transition: 0.6s all;
  height: 156px;

  /*
  |
  | Card Upper
  | ------------
  */
  .tasks-card__content {
    @extend .d-flex, .w-100, .overflow-hidden;
    position: relative;

    transition: 0.6s all;

    // Colored line
    &:before {
      content: '';
      @extend .rounded, .mr-3;

      max-height: 100%;
      width: 0.3rem;

      display: block;
      background: $gray-100;
    }
    .task-card__icon {
      @extend .rounded;
      font-size: 1.275rem;
      padding: 12px 14px !important;
      background: rgba(255, 129, 87, 0.1);
      color: $carbon;
    }

    .tasks-card__body {
      @extend .mt-2, .font-weight-normal, .mb-3, .h5;
      line-height: 1.4;
      display: -webkit-box;
      max-width: 200px;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      height: 3.75rem;
    }

    .tasks-card__footer {
      @extend .d-flex, .justify-content-between, .align-items-center;

      .tasks-card__transactions-count {
        @extend .d-flex, .align-items-center;
      }

      // Details status
      .tasks-card__transactions-checks {
        @extend .d-flex, .align-items-center;

        .checks-content {
          @extend .ml-2;
          text-align: center;
        }

        .tasks-card__transactions-checks__pending {
          @extend .d-flex, .align-items-center;

          .checks-content {
            color: $yellow;
          }
        }
        .tasks-card__transactions-checks__failed {
          @extend .d-flex, .align-items-center;

          .checks-content {
            color: $red;
          }
        }
        .tasks-card__transactions-checks__success {
          @extend .d-flex, .align-items-center;

          .checks-content {
            color: $green;
          }
        }
      }
      .tasks-card__transactions-retry {
        @extend .d-flex, .align-items-center;
        color: $red;
      }
    }
  }
}
