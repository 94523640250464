// ***
// * SCSS

@import '@julaya/common/scss/system-variables';

$jla-text-helper-font-size: $font-size-base; // 14px
$jla-text-helper-font-size-sm: $font-size-base * 0.85; // 12px

//icons
$jla-text-helper-icon-font-size: 1.25rem;
$jla-text-helper-icon-font-size-sm: 0.9375rem;
$text-helper-icon-margin: 7px;
$text-helper-icon-margin-sm: 6px;

//colors
$jla-text-helper-color: $neutral-500;
$jla-text-helper-error-color: $error-500;
$jla-text-helper-success-color: $success-500;
$jla-text-helper-warning-color: $warning-500;

.jla-text-helper {
  $self: &;
  font-size: $jla-text-helper-font-size;
  color: $jla-text-helper-color;
  display: flex;
  align-items: center;

  &__icon {
    font-size: $jla-text-helper-icon-font-size;
    margin-right: $text-helper-icon-margin;
  }

  &--sm {
    font-size: $jla-text-helper-font-size-sm;

    .jla-text-helper__icon {
      font-size: $jla-text-helper-icon-font-size-sm;
      margin-right: $text-helper-icon-margin-sm;
    }
  }

  &--warning {
    color: $jla-text-helper-warning-color;
  }
  &--error {
    color: $jla-text-helper-error-color;
  }
  &--success {
    color: $jla-text-helper-success-color;
  }
}
