// ***
// * SCSS

@import '@julaya/common/scss/system-variables';
@import '@julaya/common/scss/system';

$jla-file-upload-drop-area-border-color: $primary-500;
$jla-file-upload-drop-area-padding: 1rem;
$jla-file-upload-drop-area-border-radius: 0.625rem;
$jla-file-upload-drop-area-color: $primary-500;
$jla-file-upload-drop-area-hover-bg: $primary-50;

.jla-file-upload-file-input {
  display: none;
  visibility: hidden;
  width: 0px;
  height: 0px;
}
.jla-file-upload {
  $self: &;
  //label
  &__label {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid $neutral-100;
    label {
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 0.75rem;
      margin-bottom: 0;
      color: $neutral-900;
    }

    &--disabled {
      label {
        color: $neutral-400;
      }
    }

    #{$self}__label-hint {
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 0.75rem;
      color: $neutral-500;
    }
  }

  &__drop-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid $jla-file-upload-drop-area-border-color;
    padding: $jla-file-upload-drop-area-padding;
    border-radius: $jla-file-upload-drop-area-border-radius;
    transition: all 200ms ease-in-out;

    &--drag-over,
    &:hover {
      background-color: $jla-file-upload-drop-area-hover-bg;
    }

    &--disabled {
      opacity: 0.7;
      pointer-events: none;
    }
  }
  &__drop-area-icon {
    background-color: $primary-100 !important;
    color: $jla-file-upload-drop-area-color;
    margin-bottom: $jla-file-upload-drop-area-padding;
  }
  &__uploaded {
    padding: $jla-file-upload-drop-area-padding;
    border-radius: $jla-file-upload-drop-area-border-radius;
    display: flex;
    background-color: $neutral-100;
    &--bg-white {
      background-color: $white;
    }
  }
  &__uploaded-bullet {
    flex-grow: 1;
  }
  &__uploaded-action {
    margin-left: 0.5rem;
  }
  &__uploaded-actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
