.tasks-header {
  input[type="text"] {
    width: 200px;
  }

  .tasks-dropdown-container {
    .tasks-dropdown-link {
      @extend .px-4, .py-2, .text-muted, .d-flex, .justify-content-between;
      cursor: pointer;

      &.active {
        @extend .font-weight-bold;
        background: rgba-custom(
          var(--primary-h),
          var(--primary-s),
          var(--primary-l),
          0.1
        );
        color: $black !important;
      }
    }
  }
}
