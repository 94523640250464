@import '@julaya/common/scss/system-variables';

$display-map: (
  'display1': (
    'line-height': $display1-line-height,
    'font-size': $display1-font-size
  ),
  'display2': (
    'line-height': $display2-line-height,
    'font-size': $display2-font-size
  )
);

$heading-map: (
  'h1': (
    'line-height': $h1-line-height,
    'font-size': $h1-font-size
  ),
  'h2': (
    'line-height': $h2-line-height,
    'font-size': $h2-font-size
  ),
  'h3': (
    'line-height': $h3-line-height,
    'font-size': $h3-font-size
  ),
  'h4': (
    'line-height': $h4-line-height,
    'font-size': $h4-font-size
  ),
  'h5': (
    'line-height': $h5-line-height,
    'font-size': $h5-font-size
  ),
  'h6': (
    'line-height': $h6-line-height,
    'font-size': $h6-font-size
  )
);

$text-map: (
  'lg': (
    'line-height': $text-line-height-lg,
    'font-size': $text-font-size-lg
  ),
  'md': (
    'line-height': $text-line-height-md,
    'font-size': $text-font-size-md
  ),
  'sm': (
    'line-height': $text-line-height-sm,
    'font-size': $text-font-size-sm
  ),
  'body': (
    'line-height': $text-line-height-sm,
    'font-size': $text-font-size-sm
  ),
  'p': (
    'line-height': $text-line-height-sm,
    'font-size': $text-font-size-sm
  ),
  'xs': (
    'line-height': $text-line-height-xs,
    'font-size': $text-font-size-xs
  ),
  'small': (
    'line-height': $text-line-height-xs,
    'font-size': $text-font-size-xs
  )
);

.jla-text {
  font-family: $font-family-base;
  font-weight: $font-weight-base;

  &--lead {
    font-weight: $lead-font-weight;
  }
  &--underline {
    text-decoration: underline;
  }
  &--strike {
    text-decoration: line-through;
  }
  &--typo-clickable{
    cursor: pointer;
  }
  &--truncated-value {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 250px;
  }
  &--overflow-break {
    overflow-wrap: break-word;
  }

  @each $name, $color in $theme-colors {
    &--#{$name} {
      color: $color;
    }
  }
  @each $size, $data in $display-map {
    &--#{$size} {
      font-size: map-get($data, 'font-size');
      line-height: map-get($data, 'line-height');
    }
  }

  @each $size, $data in $heading-map {
    &--#{$size} {
      font-size: map-get($data, 'font-size');
      line-height: map-get($data, 'line-height');
    }
  }

  @each $size, $data in $text-map {
    &--#{$size} {
      font-size: map-get($data, 'font-size');
      line-height: map-get($data, 'line-height');
    }
  }
}
