@import '@julaya/common/scss/system-variables';
@import '@julaya/common/scss/system';

$jla-datepicker-focus-border: 1px solid $primary-500;
$jla-datepicker-feedback-success-color: $success-500;
$jla-datepicker-feedback-warning-color: $warning-500;
$jla-datepicker-feedback-error-color: $error-500;
$jla-datepicker-font-family: $font-family-base;
$jla-datepicker-font-weight: 500;
$jla-datepicker-font-size: $font-size-base;
$jla-datepicker-color: $neutral-900;
$jla-datepicker-placeholder-color: $neutral-400;
$jla-datepicker-background-color: $neutral-50;
$jla-datepicker-disabled-color: $neutral-400;
$jla-datepicker-icon-font-size: 1.25rem;

$jla-datepicker-padding-x: 12px;
$jla-datepicker-padding-y: 10px;
$jla-datepicker-border: 1px solid transparent;
$jla-datepicker-border-radius: 10px;

$jla-datepicker-cell-size: 40px;

.jla-datepicker {
  $self: &;
  position: relative;

  @include form-input-common(
    $self,
    $jla-datepicker-focus-border,
    $jla-datepicker-placeholder-color,
    $jla-datepicker-disabled-color,
    $jla-datepicker-feedback-success-color,
    $jla-datepicker-feedback-warning-color,
    $jla-datepicker-feedback-error-color
  );

  &__input {
    position: relative;
    border: $jla-datepicker-border;
    font-family: $jla-datepicker-font-family;
    font-weight: $jla-datepicker-font-weight;
    font-size: $jla-datepicker-font-size;
    color: $jla-datepicker-color;
    padding: $jla-datepicker-padding-y $jla-datepicker-padding-x;
    border: $jla-datepicker-border;
    background-color: $jla-datepicker-background-color;
    border-radius: $jla-datepicker-border-radius;
    line-height: 1.25;
    width: 100%;
    text-align: left;

    &--prefix {
      padding-left: 40px;
    }
    &--suffix {
      padding-right: 40px;
    }
  }

  &__placeholder {
    color: $neutral-400;
    &--hidden {
      opacity: 0;
    }
  }

  &__prefix,
  &__suffix {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: $jla-datepicker-icon-font-size;
    top: 0;
    bottom: 0;
    color: $jla-datepicker-color;
  }

  &__prefix {
    padding-left: $jla-datepicker-padding-x;
    left: 0;
  }
  &__suffix {
    padding-right: $jla-datepicker-padding-x;
    right: 0;
  }

  &__body {
    position: absolute;
    z-index: 1;

    width: 296px;
    left: 0;
    top: calc(100% + 0.675rem);
    padding: 0.5rem;

    @extend .rounded;
    background: $white;
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.15);

    // transition: 0.3s all;
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
  }

  /*
    |
    | Open
    | -----------
    */
  &--open {
    // #{$self}__suffix {
    //   transform: rotate(180deg);
    // }

    #{$self}__body {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }

    &--top {
      #{$self}__body {
        bottom: 107%;
      }
    }

    &--bottom {
      #{$self}__body {
        top: 107%;
      }
    }
  }

  .rdtPicker {
    position: relative;
    border: 0;
    padding: 0;
    box-shadow: none;
    border-radius: $jla-datepicker-border-radius;
    width: 100%;

    th {
      border-bottom: none !important;
    }

    .rdtSwitch {
      width: 100%;
    }

    .rdtPrev,
    .rdtNext {
      width: $jla-datepicker-cell-size;
      height: $jla-datepicker-cell-size;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        display: none;
      }
    }

    .rdtPrev {
      &::after {
        font-family: 'Julaya-v2';
        content: '\e9af';
      }
    }

    .rdtNext {
      &::after {
        font-family: 'Julaya-v2';
        content: '\e9b0';
      }
    }

    .rdtSwitch,
    .dow,
    .rdtDay {
      height: $jla-datepicker-cell-size;
    }

    .dow,
    .rdtDay {
      width: $jla-datepicker-cell-size;
    }

    .rdtDay,
    .rdtMonth,
    .rdtYear {
      border-radius: $jla-datepicker-border-radius;
    }

    .rdtActive {
      background-color: $primary-500 !important;
      text-shadow: none !important;

      &:hover {
        border-radius: $jla-datepicker-border-radius !important;
      }
    }

    .rdtInActiveRange {
      background-color: $primary-50 !important;
      text-shadow: none !important;
      border-radius: revert;
    }

    .startDate {
      border-top-right-radius: revert;
      border-bottom-right-radius: revert;
    }

    .endDate {
      border-top-left-radius: revert;
      border-bottom-left-radius: revert;
    }

    .rdtToday {
      color: $primary-500 !important;

      &::before {
        border-left: none !important;
        border-bottom: none !important;
        bottom: 20% !important;
        right: calc(50% - 2px) !important;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: $primary-500;
      }

      &.rdtActive {
        color: $white !important;

        &::before {
          background-color: $white;
        }
      }
    }

    .rdtNotInCurrentMonth {
      color: $neutral-500 !important;

      &.rdtActive {
        color: $white !important;
      }
    }
  }
}
