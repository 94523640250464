.query-input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 3.5rem;
  background-color: white;
  border-radius: 10px;
  margin: auto;
  margin-bottom: 1rem;
  padding: 0 1rem;
  input {
    width: 100%;
    height: 70%;
    background-color: white;
    font-size: 1rem;
  }
  i {
    font-size: 1.5rem;
  }
}
.service-content-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 4.5rem;
  background-color: white;
  margin-top: 1rem;
  border-radius: 10px;
}
.service-content-item {
  display: flex;
  margin-left: 1rem;
  img {
    border-radius: 6px !important;
    margin-right: 1rem !important;
  }
  &-title {
    font-size: 1.3rem;
    color: black;
  }
}

.line {
  width: 100%;
  opacity: 0.1;
  border: 0;
  border-top: 1px solid #000000;
  margin: 0;
}

.summary-item-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  h5,
  h6 {
    margin: 0;
  }
}

.amount-input {
  background-color: white !important;
  height: 100%;
  border-right: 1px solid #979797 !important;
  color: black;
  font-weight: bold;
  font-size: 1.1rem;
}
.amount-span {
  background-color: white;
  border-bottom-right-radius: 10px !important;
  border-top-right-radius: 10px !important;
  font-weight: bold;
  font-size: 1.1rem;
}

.fees-input {
  background-color: white !important;
  height: 100%;
  color: black;
  font-weight: bold;
  font-size: 1.1rem;
  opacity: 0.5 !important;
}
.fees-span {
  background-color: white;
  border-bottom-right-radius: 10px !important;
  border-top-right-radius: 10px !important;
  font-weight: bold;
  font-size: 1.1rem;
  opacity: 0.5;
}

.transfer-success-icon {
  padding: 1rem;
  background-color: $carbon;
  color: white;
  font-size: 1.5rem;
  border-radius: 10px;
}
