@import '@julaya/common/scss/system-variables';

.jla-timer {
  &__wrapper {
    padding: 12px 16px;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    gap: 16px;
    border-radius: 10px;
    $root: &;

    &--white {
      background-color: $white;
    }
    &--grey {
      background-color: $neutral-100;
    }
    &--outline {
      border: 1.5px solid $neutral-50;
    }
    &--dual {
      padding: 16px;
      &#{$root}--grey {
        background-color: $neutral-50;
      }
    }
  }

  &__container {
    white-space: nowrap;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
  }

  &__divider {
    background-color: $neutral-100;
    width: 1.5px;
  }
  &__box-unit {
    white-space: nowrap;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.02em;
    color: $neutral-900;
    &--time-over {
      color: $error-500;
    }
  }
  &__label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: $neutral-500;
    &--center {
      text-align: center;
    }
  }
  &__icon {
    margin-right: 8px;
  }
}
