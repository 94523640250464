// ***
// * SCSS

@import '@julaya/common/scss/system-variables';

$jla-btn-font-family: $font-family-base;
$jla-btn-font-weight: 700;

// FONT
$jla-btn-font-size: $font-size-base; // 14px
$jla-btn-font-size-lg: $font-size-base; // 14px
$jla-btn-font-size-sm: $font-size-base * 0.85; // 12px

$jla-btn-line-height: 1.28572;
$jla-btn-line-height-lg: 1.28572;
$jla-btn-line-height-sm: 1.28572;

//SPACING
$jla-btn-padding-x: 10px;
$jla-btn-padding-y: 10px;
$jla-btn-padding-x-lg: 12px;
$jla-btn-padding-y-lg: 11px;
$jla-btn-padding-x-sm: 6px;
$jla-btn-padding-y-sm: 5px;

//BORDER RADIUS
$jla-btn-border-radius: 10px;
$jla-btn-border-radius-lg: 10px;
$jla-btn-border-radius-sm: 8px;

//PREFIX SUFFIX
$jla-btn-icon-size: 24px;
$jla-btn-icon-size-lg: 20px;
$jla-btn-icon-size-sm: 20px;

$btn-map: (
  'primary': (
    'primary': (
      'default': (
        'bg': $primary-500,
        'color': $white,
        'border': 1px solid transparent
      ),
      'hover': (
        'bg': $primary-600,
        'color': $white,
        'border': 1px solid transparent
      ),
      'active': (
        'bg': $primary-600,
        'color': $primary-200,
        'border': 1px solid transparent
      ),
      'disabled': (
        'bg': $primary-300,
        'color': $white,
        'border': 1px solid transparent
      )
    ),
    'secondary': (
      'default': (
        'bg': $primary-50,
        'color': $primary-500,
        'border': 1px solid transparent
      ),
      'hover': (
        'bg': $primary-100,
        'color': $primary-600,
        'border': 1px solid transparent
      ),
      'active': (
        'bg': $primary-100,
        'color': $primary-500,
        'border': 1px solid transparent
      ),
      'disabled': (
        'bg': $primary-50,
        'color': $primary-300,
        'border': 1px solid transparent
      )
    ),
    'tertiary': (
      'default': (
        'bg': $neutral-200,
        'color': $neutral-700,
        'border': 1px solid transparent
      ),
      'hover': (
        'bg': $neutral-300,
        'color': $neutral-700,
        'border': 1px solid transparent
      ),
      'active': (
        'bg': $neutral-300,
        'color': $neutral-500,
        'border': 1px solid transparent
      ),
      'disabled': (
        'bg': $neutral-200,
        'color': $neutral-400,
        'border': 1px solid transparent
      )
    ),
    'outline': (
      'default': (
        'bg': transparent,
        'color': $primary-500,
        'border': 1px solid $primary-500
      ),
      'hover': (
        'bg': transparent,
        'color': $primary-600,
        'border': 1px solid $primary-400
      ),
      'active': (
        'bg': transparent,
        'color': $primary-400,
        'border': 1px solid $primary-400
      ),
      'disabled': (
        'bg': transparent,
        'color': $primary-300,
        'border': 1px solid $primary-300
      )
    ),
    'ghost': (
      'default': (
        'bg': transparent,
        'color': $primary-500,
        'border': 1px solid transparent
      ),
      'hover': (
        'bg': transparent,
        'color': $primary-600,
        'border': 1px solid transparent
      ),
      'active': (
        'bg': transparent,
        'color': $primary-400,
        'border': 1px solid transparent
      ),
      'disabled': (
        'bg': transparent,
        'color': $primary-300,
        'border': 1px solid transparent
      )
    )
  ),
  'error': (
    'primary': (
      'default': (
        'bg': $error-500,
        'color': $white,
        'border': 1px solid transparent
      ),
      'hover': (
        'bg': $error-600,
        'color': $white,
        'border': 1px solid transparent
      ),
      'active': (
        'bg': $error-600,
        'color': $error-200,
        'border': 1px solid transparent
      ),
      'disabled': (
        'bg': $error-300,
        'color': $white,
        'border': 1px solid transparent
      )
    ),
    'secondary': (
      'default': (
        'bg': $error-50,
        'color': $error-500,
        'border': 1px solid transparent
      ),
      'hover': (
        'bg': $error-100,
        'color': $error-600,
        'border': 1px solid transparent
      ),
      'active': (
        'bg': $error-100,
        'color': $error-500,
        'border': 1px solid transparent
      ),
      'disabled': (
        'bg': $error-50,
        'color': $error-300,
        'border': 1px solid transparent
      )
    ),
    'tertiary': (
      'default': (
        'bg': $white,
        'color': $error-500,
        'border': 1px solid $error-200
      ),
      'hover': (
        'bg': $error-50,
        'color': $error-500,
        'border': 1px solid $error-300
      ),
      'active': (
        'bg': $error-50,
        'color': $error-500,
        'border': 1px solid $error-400
      ),
      'disabled': (
        'bg': $white,
        'color': $error-300,
        'border': 1px solid $error-200
      )
    ),
    'outline': (
      'default': (
        'bg': transparent,
        'color': $error-500,
        'border': 1px solid $error-500
      ),
      'hover': (
        'bg': transparent,
        'color': $error-600,
        'border': 1px solid $error-400
      ),
      'active': (
        'bg': transparent,
        'color': $error-400,
        'border': 1px solid $error-400
      ),
      'disabled': (
        'bg': transparent,
        'color': $error-300,
        'border': 1px solid $error-300
      )
    ),
    'ghost': (
      'default': (
        'bg': transparent,
        'color': $error-500,
        'border': 1px solid transparent
      ),
      'hover': (
        'bg': transparent,
        'color': $error-600,
        'border': 1px solid transparent
      ),
      'active': (
        'bg': transparent,
        'color': $error-400,
        'border': 1px solid transparent
      ),
      'disabled': (
        'bg': transparent,
        'color': $error-300,
        'border': 1px solid transparent
      )
    )
  ),
  'success': (
    'primary': (
      'default': (
        'bg': $success-500,
        'color': $white,
        'border': 1px solid transparent
      ),
      'hover': (
        'bg': $success-600,
        'color': $white,
        'border': 1px solid transparent
      ),
      'active': (
        'bg': $success-600,
        'color': $success-200,
        'border': 1px solid transparent
      ),
      'disabled': (
        'bg': $success-300,
        'color': $white,
        'border': 1px solid transparent
      )
    ),
    'secondary': (
      'default': (
        'bg': $success-50,
        'color': $success-500,
        'border': 1px solid transparent
      ),
      'hover': (
        'bg': $success-100,
        'color': $success-600,
        'border': 1px solid transparent
      ),
      'active': (
        'bg': $success-100,
        'color': $success-500,
        'border': 1px solid transparent
      ),
      'disabled': (
        'bg': $success-50,
        'color': $success-300,
        'border': 1px solid transparent
      )
    ),
    'tertiary': (
      'default': (
        'bg': $white,
        'color': $success-500,
        'border': 1px solid $success-200
      ),
      'hover': (
        'bg': $success-50,
        'color': $success-500,
        'border': 1px solid $success-300
      ),
      'active': (
        'bg': $success-50,
        'color': $success-500,
        'border': 1px solid $success-400
      ),
      'disabled': (
        'bg': $white,
        'color': $success-300,
        'border': 1px solid $success-200
      )
    ),
    'outline': (
      'default': (
        'bg': transparent,
        'color': $success-500,
        'border': 1px solid $success-500
      ),
      'hover': (
        'bg': transparent,
        'color': $success-600,
        'border': 1px solid $success-400
      ),
      'active': (
        'bg': transparent,
        'color': $success-400,
        'border': 1px solid $success-400
      ),
      'disabled': (
        'bg': transparent,
        'color': $success-300,
        'border': 1px solid $success-300
      )
    ),
    'ghost': (
      'default': (
        'bg': transparent,
        'color': $success-500,
        'border': 1px solid transparent
      ),
      'hover': (
        'bg': transparent,
        'color': $success-600,
        'border': 1px solid transparent
      ),
      'active': (
        'bg': transparent,
        'color': $success-400,
        'border': 1px solid transparent
      ),
      'disabled': (
        'bg': transparent,
        'color': $success-300,
        'border': 1px solid transparent
      )
    )
  ),
  'warning': (
    'primary': (
      'default': (
        'bg': $warning-500,
        'color': $white,
        'border': 1px solid transparent
      ),
      'hover': (
        'bg': $warning-600,
        'color': $white,
        'border': 1px solid transparent
      ),
      'active': (
        'bg': $warning-600,
        'color': $warning-200,
        'border': 1px solid transparent
      ),
      'disabled': (
        'bg': $warning-300,
        'color': $white,
        'border': 1px solid transparent
      )
    ),
    'secondary': (
      'default': (
        'bg': $warning-50,
        'color': $warning-500,
        'border': 1px solid transparent
      ),
      'hover': (
        'bg': $warning-100,
        'color': $warning-600,
        'border': 1px solid transparent
      ),
      'active': (
        'bg': $warning-100,
        'color': $warning-500,
        'border': 1px solid transparent
      ),
      'disabled': (
        'bg': $warning-50,
        'color': $warning-300,
        'border': 1px solid transparent
      )
    ),
    'tertiary': (
      'default': (
        'bg': $white,
        'color': $warning-500,
        'border': 1px solid $warning-200
      ),
      'hover': (
        'bg': $warning-50,
        'color': $warning-500,
        'border': 1px solid $warning-300
      ),
      'active': (
        'bg': $warning-50,
        'color': $warning-500,
        'border': 1px solid $warning-400
      ),
      'disabled': (
        'bg': $white,
        'color': $warning-300,
        'border': 1px solid $warning-200
      )
    ),
    'outline': (
      'default': (
        'bg': transparent,
        'color': $warning-500,
        'border': 1px solid $warning-500
      ),
      'hover': (
        'bg': transparent,
        'color': $warning-600,
        'border': 1px solid $warning-400
      ),
      'active': (
        'bg': transparent,
        'color': $warning-400,
        'border': 1px solid $warning-400
      ),
      'disabled': (
        'bg': transparent,
        'color': $warning-300,
        'border': 1px solid $warning-300
      )
    ),
    'ghost': (
      'default': (
        'bg': transparent,
        'color': $warning-500,
        'border': 1px solid transparent
      ),
      'hover': (
        'bg': transparent,
        'color': $warning-600,
        'border': 1px solid transparent
      ),
      'active': (
        'bg': transparent,
        'color': $warning-400,
        'border': 1px solid transparent
      ),
      'disabled': (
        'bg': transparent,
        'color': $warning-300,
        'border': 1px solid transparent
      )
    )
  ),
  'neutral': (
    'primary': (
      'default': (
        'bg': $white,
        'color': $neutral-500,
        'border': 1px solid transparent
      ),
      'hover': (
        'bg': $neutral-300,
        'color': $neutral-500,
        'border': 1px solid transparent
      ),
      'active': (
        'bg': $neutral-300,
        'color': $neutral-400,
        'border': 1px solid transparent
      ),
      'disabled': (
        'bg': $neutral-100,
        'color': $neutral-400,
        'border': 1px solid transparent
      )
    ),
    'secondary': (
      'default': (
        'bg': $neutral-50,
        'color': $neutral-500,
        'border': 1px solid transparent
      ),
      'hover': (
        'bg': $neutral-100,
        'color': $neutral-600,
        'border': 1px solid transparent
      ),
      'active': (
        'bg': $neutral-100,
        'color': $neutral-500,
        'border': 1px solid transparent
      ),
      'disabled': (
        'bg': $neutral-50,
        'color': $neutral-300,
        'border': 1px solid transparent
      )
    ),
    'tertiary': (
      'default': (
        'bg': $neutral-200,
        'color': $neutral-500,
        'border': 1px solid $neutral-200
      ),
      'hover': (
        'bg': $neutral-50,
        'color': $neutral-400,
        'border': 1px solid $neutral-300
      ),
      'active': (
        'bg': $neutral-50,
        'color': $neutral-500,
        'border': 1px solid $neutral-400
      ),
      'disabled': (
        'bg': $white,
        'color': $neutral-300,
        'border': 1px solid $neutral-200
      )
    ),
    'outline': (
      'default': (
        'bg': transparent,
        'color': $neutral-500,
        'border': 1px solid $neutral-500
      ),
      'hover': (
        'bg': transparent,
        'color': $neutral-600,
        'border': 1px solid $neutral-400
      ),
      'active': (
        'bg': transparent,
        'color': $neutral-400,
        'border': 1px solid $neutral-400
      ),
      'disabled': (
        'bg': transparent,
        'color': $neutral-300,
        'border': 1px solid $neutral-300
      )
    ),
    'ghost': (
      'default': (
        'bg': transparent,
        'color': $neutral-500,
        'border': 1px solid transparent
      ),
      'hover': (
        'bg': transparent,
        'color': $neutral-600,
        'border': 1px solid transparent
      ),
      'active': (
        'bg': transparent,
        'color': $neutral-400,
        'border': 1px solid transparent
      ),
      'disabled': (
        'bg': transparent,
        'color': $neutral-300,
        'border': 1px solid transparent
      )
    )
  )
);

.jla-btn {
  // base
  display: flex;
  align-items: center;
  line-height: 1.28572;
  // text-align: center;
  // vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;

  background-color: $primary-500;
  color: $white;
  cursor: pointer;
  font-family: $jla-btn-font-family;
  // sizes
  font-weight: $jla-btn-font-weight;
  padding: $jla-btn-padding-y $jla-btn-padding-x;
  // line-height: $jla-btn-line-height;
  font-size: $jla-btn-font-size;
  border-radius: $jla-btn-border-radius;
  transition: all 0.32s ease;
  i {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.25rem;
    line-height: 5px;
  }

  // content
  > span {
    font-family: $jla-btn-font-family;
    font-weight: $jla-btn-font-weight;
    // line-height: $jla-btn-line-height;
  }

  // modifiers
  &--nowrap {
    white-space: nowrap;
    > span {
      white-space: nowrap;
    }
  }

  &--md {
    padding: $jla-btn-padding-y $jla-btn-padding-x;
    line-height: $jla-btn-line-height;
    font-size: $jla-btn-font-size;
    border-radius: $jla-btn-border-radius;
    > span {
      font-size: $jla-btn-font-size;
    }
  }
  &--lg {
    padding: $jla-btn-padding-y-lg $jla-btn-padding-x-lg;
    line-height: $jla-btn-line-height-lg;
    font-size: $jla-btn-font-size-lg;
    border-radius: $jla-btn-border-radius-lg;
    > span {
      font-size: $jla-btn-font-size-lg;
    }
  }
  &--sm {
    padding: $jla-btn-padding-y-sm $jla-btn-padding-x-sm;
    line-height: $jla-btn-line-height-sm;
    font-size: $jla-btn-font-size-sm;
    border-radius: $jla-btn-border-radius-sm;
    > span {
      font-size: $jla-btn-font-size-sm;
    }
    > i {
      font-size: 1rem;
    }
  }
  &--block {
    display: block;
    text-align: center;
    width: 100%;
  }
  //type && color && status

  @each $color, $types in $btn-map {
    @each $type, $data in $types {
      // Variables
      $default: map-get($data, 'default');
      $hover: map-get($data, 'hover');
      $active: map-get($data, 'active');
      $disabled: map-get($data, 'disabled');

      &--#{$color}-#{$type} {
        // CSS
        background-color: map-get($default, 'bg');
        color: map-get($default, 'color');
        border: map-get($default, 'border');
        &:hover {
          background-color: map-get($hover, 'bg');
          color: map-get($hover, 'color');
          border: map-get($hover, 'border');
        }
        &:active {
          background-color: map-get($active, 'bg');
          color: map-get($active, 'color');
          border: map-get($active, 'border');
        }
        &:disabled {
          background-color: map-get($disabled, 'bg');
          color: map-get($disabled, 'color');
          border: map-get($disabled, 'border');
        }
        &:focus {
          outline: none;
        }
      }

      &--#{$color}-#{$type}-disabled {
        background-color: map-get($disabled, 'bg');
        color: map-get($disabled, 'color');
        border: map-get($disabled, 'border');
      }
    }
  }

  // elements
  &__prefix {
    margin-right: $jla-btn-padding-x - 2;
    &--lg {
      margin-right: $jla-btn-padding-x-lg - 2;
    }
    &--sm {
      margin-right: $jla-btn-padding-x-sm - 2;
    }
  }
  &__suffix {
    margin-left: $jla-btn-padding-x - 2;
    &--lg {
      margin-left: $jla-btn-padding-x-lg - 2;
    }
    &--sm {
      margin-left: $jla-btn-padding-x-sm - 2;
    }
  }
}
