@import '@julaya/common/scss/system-variables';

$colors-map: (
  'primary': (
    'bg': $lilas-50,
    'border': $lilas-200
  ),
  'success': (
    'bg': $success-50,
    'border': $success-200
  ),
  'error': (
    'bg': $error-50,
    'border': $error-200
  ),
  'warning': (
    'bg': $warning-50,
    'border': $warning-200
  )
);

$jla-alert-padding: 1rem;
$jla-alert-border-radius: 0.625rem;

$jla-alert-close-size: 1.25rem;
$jla-alert-close-color: $neutral-900;

.jla-alert {
  padding: $jla-alert-padding;
  border-radius: $jla-alert-border-radius;
  &--inline {
    flex-direction: row;
  }
  &--stacked,
  &--stacked-full {
    flex-direction: column;
  }

  @each $color, $data in $colors-map {
    &--#{$color} {
      border: 1px solid map-get($data, 'border');
      background-color: map-get($data, 'bg');
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__children {
    display: flex;
    align-items: center;
  }
  &__actions {
    display: flex;
    gap: $spacer / 2;
    align-items: center;
    button {
      white-space: nowrap;
    }
  }
  &__close {
    font-size: $jla-alert-close-size;
    color: $jla-alert-close-color;
  }
  &__footer {
    margin-top: 1rem;
    display: flex;
    gap: $spacer / 2;
  }
  &__footer-stack-full {
    button {
      flex: 1;
      display: flex;
      justify-content: center;
    }
  }
}
