body div#hubspot-messages-iframe-container {
  max-height: calc(100% - 5rem) !important;
  height: calc(100% - 5rem) !important;
  bottom: 0px !important;
  transition: all 1s;
  transform: translateY(-10%);

  &.custom-hubspot {
    transform: translateY(0%);
    bottom: 65px !important;
    max-height: 92.5%;
  }
}
