@keyframes slideUp {
  0% {
    opacity: 0;
    visibility: hidden;

    transform: translateY(1rem);
  }
  100% {
    opacity: 1;
    visibility: visible;

    transform: translateY(0.6rem);
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInLeft {
  0% {
    opacity: 0;
    visibility: hidden;

    transform: translateX(-0.6rem);
  }
  100% {
    opacity: 1;
    visibility: visible;

    transform: translateX(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
