@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  outline: 0;
  justify-content: flex-end;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
  &.open {
    opacity: 1;
    animation: fadeIn 0.2s linear;
    display: flex;
    .overlay-inner {
      transform: translateY(0px);
    }
  }
  .overlay-inner {
    @extend .p-3;
    transition: transform 300ms ease-in-out 20ms;

    transform: translateY(100%);
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    outline: 0;
    border-top-left-radius: $border-radius * 2;
    border-top-right-radius: $border-radius * 2;
    max-height: calc(100vh - 28px);
    padding-bottom: 5rem !important;

    .overlay-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow-y: auto;
    }

    header {
      @extend .pb-3, .border-bottom;
    }
    footer {
      @extend .pt-3, .border-top;
    }
  }
}
