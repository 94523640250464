@font-face {
  font-family: 'julaya-v2';

  src: url('../../../assets/fonts/julaya-fonticon-v2/julaya-v2.eot?tmdyc5');
  src: url('../../../assets/fonts/julaya-fonticon-v2/julaya-v2.eot?tmdyc5#iefix') format('embedded-opentype'),
    url('../../../assets/fonts/julaya-fonticon-v2/julaya-v2.ttf?tmdyc5') format('truetype'),
    url('../../../assets/fonts/julaya-fonticon-v2/julaya-v2.woff?tmdyc5') format('woff'),
    url('../../../assets/fonts/julaya-fonticon-v2/julaya-v2.svg?tmdyc5#julaya-v2') format('svg');

  font-weight: normal;
  font-style: normal;
  font-display: block;
}
