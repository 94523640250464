@import '@julaya/common/scss/system-variables';

.jla-bullet {
  display: flex;
  align-items: center;
  &__content {
    flex-grow: 1;
    margin-left: $spacer / 2;
  }
  &__icon {
    font-size: 1.25rem;
    .jla-text {
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 0;
    }
  }
}
