// ***
// * SCSS

@import '@julaya/common/scss/system-variables';
@import '@julaya/common/scss/system';

//base
$jla-amount-font-family: $font-family-base;
$jla-amount-font-weight: 700;
$jla-amount-font-size: 1.5rem;
$jla-amount-icon-font-size: 1.25rem;
$jla-amount-color: $neutral-900;
$jla-amount-placeholder-color: $neutral-400;
$jla-amount-background-color: $neutral-50;
$jla-amount-disabled-color: $neutral-400;
$jla-amount-label: $neutral-400;

$jla-amount-padding: 12px;
$jla-amount-padding-top: 40px;
$jla-amount-border: 1px solid transparent;
$jla-amount-border-radius: 10px;

//focus
$jla-amount-focus-border: 1px solid $primary-500;

$jla-amount-feedback-error-color: $error-500;
$jla-amount-feedback-success-color: $success-500;
$jla-amount-feedback-warning-color: $warning-500;

// wrapper
.jla-amount {
  $self: &;
  position: relative;

  @include form-input-common(
    $self,
    $jla-amount-focus-border,
    $jla-amount-placeholder-color,
    $jla-amount-disabled-color,
    $jla-amount-feedback-success-color,
    $jla-amount-feedback-warning-color,
    $jla-amount-feedback-error-color
  );

  &__label {
    position: absolute;
    left: $jla-amount-padding;
    top: 1rem;
    width: calc(100% - 24px);

    label {
      margin-bottom: 0;
      color: $jla-amount-label;
      font-size: 0.875rem;
    }
  }

  // input field
  &__input {
    border: $jla-amount-border;
    font-family: $jla-amount-font-family;
    font-weight: $jla-amount-font-weight;
    font-size: $jla-amount-font-size;
    color: $jla-amount-color;
    padding: $jla-amount-padding-top $jla-amount-padding $jla-amount-padding;
    border: $jla-amount-border;
    background-color: $jla-amount-background-color;
    border-radius: $jla-amount-border-radius;
    line-height: 1.5;
    width: 100%;
  }
}
