// ***
// * SCSS

@import '@julaya/common/scss/system-variables';

$jla-tag-font-family: $font-family-base;
$jla-tag-font-weight: 700;

// FONT
$jla-tag-font-size: $font-size-base * 0.85; // 12px
$jla-tag-font-size-md: $font-size-base * 0.85; // 12px
$jla-tag-font-size-lg: $font-size-base * 0.85; // 12px

$jla-tag-line-height: 20px;
$jla-tag-line-height-lg: 20px;
$jla-tag-line-height-md: 20px;

//SPACING
$jla-tag-padding-x: 4px;
$jla-tag-padding-y: 0px;
$jla-tag-padding-x-md: 4px;
$jla-tag-padding-y-md: 4px;
$jla-tag-padding-x-lg: 6px;
$jla-tag-padding-y-lg: 6px;

//BORDER RADIUS
$jla-tag-border-radius: 8px;
$jla-tag-border-radius-md: 8px;
$jla-tag-border-radius-lg: 8px;

//PREFIX SUFFIX
$jla-tag-icon-size: 1rem;
$jla-tag-icon-size-lg: 1.25rem;
$jla-tag-icon-size-md: 1.25rem;

$tag-map: (
  'primary': (
    'primary': (
      'bg': $carbon,
      'color': $white,
      'border': 0px solid transparent
    ),
    'secondary': (
      'bg': $lilas-50,
      'color': $neutral-900,
      'border': 0px solid transparent
    ),
    'outline': (
      'bg': transparent,
      'color': $neutral-900,
      'border': 1px solid $lilas-500
    ),
    'ghost': (
      'bg': transparent,
      'color': $lilas-500,
      'border': 0px solid transparent
    )
  ),
  'success': (
    'primary': (
      'bg': $success-500,
      'color': $white,
      'border': 0px solid transparent
    ),
    'secondary': (
      'bg': $success-50,
      'color': $neutral-900,
      'border': 0px solid transparent
    ),
    'outline': (
      'bg': transparent,
      'color': $neutral-900,
      'border': 1px solid $success-500
    ),
    'ghost': (
      'bg': transparent,
      'color': $success-500,
      'border': 0px solid transparent
    )
  ),
  'error': (
    'primary': (
      'bg': $error-500,
      'color': $white,
      'border': 0px solid transparent
    ),
    'secondary': (
      'bg': $error-50,
      'color': $neutral-900,
      'border': 0px solid transparent
    ),
    'outline': (
      'bg': transparent,
      'color': $neutral-900,
      'border': 1px solid $error-500
    ),
    'ghost': (
      'bg': transparent,
      'color': $error-500,
      'border': 0px solid transparent
    )
  ),
  'warning': (
    'primary': (
      'bg': $warning-500,
      'color': $white,
      'border': 0px solid transparent
    ),
    'secondary': (
      'bg': $warning-50,
      'color': $neutral-900,
      'border': 0px solid transparent
    ),
    'outline': (
      'bg': transparent,
      'color': $neutral-900,
      'border': 1px solid $warning-500
    ),
    'ghost': (
      'bg': transparent,
      'color': $warning-500,
      'border': 0px solid transparent
    )
  ),
  'neutral': (
    'primary': (
      'bg': $neutral-900,
      'color': $white,
      'border': 0px solid transparent
    ),
    'secondary': (
      'bg': $neutral-100,
      'color': $neutral-900,
      'border': 0px solid transparent
    ),
    'outline': (
      'bg': transparent,
      'color': $neutral-900,
      'border': 1px solid $neutral-200
    ),
    'ghost': (
      'bg': transparent,
      'color': $neutral-500,
      'border': 0px solid transparent
    )
  ),
  'blue': (
    'primary': (
      'bg': $blue-500,
      'color': $white,
      'border': 0px solid transparent
    ),
    'secondary': (
      'bg': $blue-50,
      'color': $neutral-900,
      'border': 0px solid transparent
    ),
    'outline': (
      'bg': transparent,
      'color': $neutral-900,
      'border': 1px solid $blue-500
    ),
    'ghost': (
      'bg': transparent,
      'color': $blue-500,
      'border': 0px solid transparent
    )
  ),
  'purple': (
    'primary': (
      'bg': $purple-500,
      'color': $white,
      'border': 0px solid transparent
    ),
    'secondary': (
      'bg': $purple-50,
      'color': $neutral-900,
      'border': 0px solid transparent
    ),
    'outline': (
      'bg': transparent,
      'color': $neutral-900,
      'border': 1px solid $purple-500
    ),
    'ghost': (
      'bg': transparent,
      'color': $purple-500,
      'border': 0px solid transparent
    )
  )
);

.jla-tag {
  $self: &;
  // base
  display: inline-flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  border: 0px solid transparent;
  line-height: $jla-tag-line-height;

  background-color: $lilas-500;
  color: $white;
  font-family: $jla-tag-font-family;
  // sizes
  font-weight: $jla-tag-font-weight;
  padding: $jla-tag-padding-y $jla-tag-padding-x;
  line-height: $jla-tag-line-height;
  font-size: $jla-tag-font-size;
  border-radius: $jla-tag-border-radius;
  transition: all 0.32s ease;

  // content
  > span {
    font-family: $jla-tag-font-family;
    font-weight: $jla-tag-font-weight;
    line-height: $jla-tag-line-height;
  }

  #{$self}__prefix,
  #{$self}__suffix {
    font-size: $jla-tag-icon-size;
    display: flex;
    align-items: center;
    margin-right: 0;
  }

  // modifiers
  &--lg {
    padding: $jla-tag-padding-y-lg $jla-tag-padding-x-lg;
    line-height: $jla-tag-line-height-lg;
    font-size: $jla-tag-font-size-lg;
    border-radius: $jla-tag-border-radius-lg;
    > span {
      font-size: $jla-tag-font-size-lg;
    }
    #{$self}__prefix,
    #{$self}__suffix {
      font-size: $jla-tag-icon-size-lg;
    }
  }
  &--md {
    padding: $jla-tag-padding-y-md $jla-tag-padding-x-md;
    line-height: $jla-tag-line-height-md;
    font-size: $jla-tag-font-size-md;
    border-radius: $jla-tag-border-radius-md;
    > span {
      font-size: $jla-tag-font-size-md;
    }
    #{$self}__prefix,
    #{$self}__suffix {
      font-size: $jla-tag-icon-size-md;
    }
  }

  //type && color && status

  @each $color, $types in $tag-map {
    @each $type, $data in $types {
      // CSS
      &--#{$color}-#{$type} {
        background-color: map-get($data, 'bg');
        color: map-get($data, 'color');
        border: map-get($data, 'border');
      }
    }
  }

  // elements
  &__prefix {
    margin-right: $jla-tag-padding-x;
    &--lg {
      margin-right: $jla-tag-padding-x-lg;
    }
    &--md {
      margin-right: $jla-tag-padding-x-md;
    }
  }
  &__suffix {
    margin-left: $jla-tag-padding-x;
    &--lg {
      margin-left: $jla-tag-padding-x-lg;
    }
    &--md {
      margin-left: $jla-tag-padding-x-md;
    }
  }
}
