// ***
// * SCSS

@import '@julaya/common/scss/system-variables';

$jla-progress-label-font-size: $font-size-base; // 14px
$jla-progress-label-font-weight: 700;
$jla-progress-label-color: $black;
$jla-progress-feedback-font-size: $font-size-base * 0.85; // 12px
$jla-progress-feedback-font-weight: 500;
$jla-progress-feedback-color: $neutral-500;

$jla-progress-color: $primary-500;
$jla-progress-bar-bg: $neutral-200;
$jla-progress-bar-height: 1.5rem;
$jla-progress-bar-height-sm: 1rem;
$jla-progress-bar-height-lg: 2rem;

.jla-progress {
  $self: &;

  &__label {
    color: $jla-progress-label-color;
    font-weight: $jla-progress-label-font-weight;
    font-size: $jla-progress-label-font-size;
    margin-bottom: 4px;
  }
  &__feedback {
    color: $jla-progress-feedback-color;
    font-weight: $jla-progress-feedback-font-weight;
    font-size: $jla-progress-feedback-font-size;
    margin-top: 4px;
  }
  &__bar {
    display: block;
    position: relative;
    background-color: $jla-progress-bar-bg;
    border-radius: 1rem;
    height: $jla-progress-bar-height;
  }
  &__bar-inner {
    min-width: 0;
    transition: min-width 300ms ease-in-out;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 1rem;
    background-color: $jla-progress-color;
  }

  // modifiers

  &--sm {
    #{$self}__bar {
      height: $jla-progress-bar-height-sm;
    }
  }
  &--md {
    #{$self}__bar {
      height: $jla-progress-bar-height;
    }
  }
  &--lg {
    #{$self}__bar {
      height: $jla-progress-bar-height-lg;
    }
  }
}
