.tabs {
  a {
    flex-grow: 1;
    flex-basis: 0;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-weight: bold;
    color: $text-muted;
    &.active {
      border-bottom: 1px solid $primary;
      margin-bottom: -1px;
      color: $black;
    }
  }
}
.services {
  a {
    flex-basis: 0;
    text-align: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: $text-muted;
    text-align: center;
    align-items: center;
    .image-wrapper {
      height: 2.8rem;
      width: 2.8rem;
      padding: 3px;
      border-radius: 5px;
      margin-left: auto;
      margin-right: auto;
      transition: all 500ms;
    }
    img {
      height: 100%;
      width: 100%;
      border-radius: 5px;
    }
    span {
      font-size: $font-size-base / 1.35;
    }
    &.active .image-wrapper {
      background-color: $primary;
    }
  }
}

.transaction-type-icon {
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 0.5rem;
  border-radius: 50%;
}

.bank-disposal-steps,
.merchant-payment-steps,
.momo-deposit-steps {
  div {
    width: 7px;
    height: 7px;
    border-radius: 3px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: $gray-400;
    &.active {
      background-color: $primary;
    }
  }
}

p.subscription-bullet {
  padding-left: 1.9rem;
  color: $text-grey;
  position: relative;
  font-weight: normal;
  &.checked {
    color: $black;
    &:before {
      font-family: "Julaya" !important;
      font-size: 1rem;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      content: "\e96c";
      color: $success;
    }
  }
}
.subscription-option {
  position: relative;
  .indicator {
    width: 1rem;
    height: 1rem;
    border-radius: 0.5rem;
    background-color: $gray-400;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      width: 0.7rem;
      height: 0.7rem;
      border-radius: 0.5rem;
      border: 1px solid $white;
      top: 0.15rem;
      left: 0.15rem;
    }
  }
  &.active {
    border: 1px solid $primary !important;
    .indicator {
      background-color: $primary !important;
    }
  }
}

.formfield-amount {
  padding-top: 0.6rem !important;
  padding-bottom: 0.6rem !important;
  line-height: 1.4rem !important;
}
